import React from 'react';
import CounterCard from './CounterCard';
import { monitoringComponents } from '../../../../utils/utils';

const sortedMonitoringComponents = [...monitoringComponents].sort((a, b) => a.order - b.order);

const CounterCards = () => (
  <div className="counter-cards">
    {sortedMonitoringComponents?.map((item) => {
      if (item.type === 'counter') {
        return <CounterCard key={item.id} title={item.title} source={item.source} />;
      }
      return null;
    })}
  </div>
);

export default CounterCards;
