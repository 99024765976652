import { call, put, takeEvery } from 'redux-saga/effects';
import { getCanalTypesService } from '../services/canalTypeService';
import { getCanalWidthsService, postCanalWidthService } from '../services/canalWidthService';
import { CANALWIDTH, getCanalWidthsAction, postCanalWidthAction } from '../actions/canalWidthAction';
import { handleSagaErrorNotification, handleSagaSuccessNotification } from '../utils/responseHandler';
import { isProdEnv } from '../../utils/utils';

export function* getCanalWidths({ payload }) {
  const { token } = payload;
  try {
    const canalWidths = yield call(getCanalWidthsService, token);
    const data = canalWidths?.data;

    if (data) {
      yield put(getCanalWidthsAction.success(data));
    }
  } catch (err) {
    if (!isProdEnv) {
      handleSagaErrorNotification(err, 'canalWidth');
    }
    yield put(getCanalWidthsAction.failure(err));
  }
}

export function* postCanalWidth({ payload }) {
  const token = payload[1];
  try {
    const canalWidthResponse = yield call(postCanalWidthService, payload);
    const canalWidths = yield call(getCanalTypesService, token);

    if (canalWidthResponse) {
      const descriptionSuccess = 'shared.notification.canal.successPostWidth';
      yield put(postCanalWidthAction.success(descriptionSuccess));
      yield put(getCanalWidthsAction.success(canalWidths.data));
      yield put(postCanalWidthAction.failure(null));
      handleSagaSuccessNotification(descriptionSuccess);
    }
  } catch (err) {
    handleSagaErrorNotification(err, 'canalWidth');
    yield put(postCanalWidthAction.failure(err));
  }
}

export function* canalWidthsSaga() {
  yield takeEvery(CANALWIDTH.FETCH.REQUEST, getCanalWidths);
  yield takeEvery(CANALWIDTH.POST.REQUEST, postCanalWidth);
}
