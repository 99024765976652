import React from 'react';
import PropTypes from 'prop-types';
import UpdateData from '../../shared/UpdateData';

const HeaderList = ({ name, stateToUpdate, visibleUpdateData }) => (
  <div className="header-list">
    <h3>{name}</h3>
    {visibleUpdateData && stateToUpdate && <UpdateData stateToUpdate={stateToUpdate} /> }
  </div>
);

export default HeaderList;

HeaderList.propTypes = {
  name: PropTypes.string.isRequired,
  stateToUpdate: PropTypes.shape({
    request: PropTypes.func,
  }).isRequired,
  visibleUpdateData: PropTypes.bool,
};

HeaderList.defaultProps = {
  visibleUpdateData: false,
};
