import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ANALYSIS, ANALYSISDETAILS, getAnalysisAction, getAnalysisDetailsAction,
} from '../actions/analysisAction';
import { getAnalysisByUserService, getAnalysisService } from '../services/analysisService';
import { handleSagaErrorNotification } from '../utils/responseHandler';
import { isProdEnv } from '../../utils/utils';

export const selectGetAnalysisService = (role) => (
  role !== 1 ? getAnalysisByUserService : getAnalysisService
);

export function* getAnalysis({ payload }) {
  const { showError, token, role } = payload;
  const service = selectGetAnalysisService(role);
  try {
    const analysis = yield call(service, token);
    const data = analysis?.data;
    if (data) {
      yield put(getAnalysisAction.success(data));
    } else yield put(getAnalysisAction.error("Pas d'analyses disponibles"));
  } catch (err) {
    if (showError) {
      handleSagaErrorNotification(err, 'analyse');
    }
    yield put(getAnalysisAction.failure(err));
  }
}

export function* getAnalysisSaga() {
  yield takeEvery(ANALYSIS.FETCH.REQUEST, getAnalysis);
}

export function* getAnalysisDetails({ payload }) {
  const { analysis, recordsList } = payload;

  const getAgencyAnalysis = () => {
    const detailsByAgency = [];

    analysis?.forEach((analyse) => {
      const {
        agencyName, telephoneNumber, decision, qualification, timeStamp,
      } = analyse;

      const agencyExist = detailsByAgency.find((detailAgency) => detailAgency.name === agencyName);

      if (!agencyExist) {
        const newAgency = {
          name: agencyName,
          details: [],
        };
        detailsByAgency.push(newAgency);
      }

      const agencyData = detailsByAgency.find((detailAgency) => detailAgency.name === agencyName);
      const { details } = agencyData;

      let mobile = details.find((detail) => detail.telephoneNumber === telephoneNumber);

      if (!mobile) {
        mobile = {
          telephoneNumber,
          fuites: [],
          sansFuites: [],
          qualificationOK: [],
          qualificationNOK: [],
          noSignal: [],
        };

        agencyData.details.push(mobile);
      }

      if (decision === 1) {
        mobile.fuites.push(timeStamp);
      } else {
        mobile.sansFuites.push(timeStamp);
      }

      if (qualification === decision) {
        mobile.qualificationOK.push(timeStamp);
      } else {
        mobile.qualificationNOK.push(timeStamp);
      }
      const allRecordsList = recordsList
        .filter((record) => record.numberMobile === mobile.numberMobile);

      mobile.noSignal = allRecordsList?.map((record) => record.timeStamp)
        .filter((timestamp) => !analysis.some((item) => item.timeStamp === timestamp));
    });
    return detailsByAgency;
  };

  try {
    yield put(getAnalysisDetailsAction.success(getAgencyAnalysis()));
  } catch (error) {
    if (!isProdEnv) {
      handleSagaErrorNotification(error, 'analyse');
    }
    yield put(getAnalysisDetailsAction.failure(error));
  }
}

export function* getAnalysisDetailsSaga() {
  yield takeEvery(ANALYSISDETAILS.FETCH.REQUEST, getAnalysisDetails);
}
