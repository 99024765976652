import { Progress } from 'antd';
import React, { useEffect, useState } from 'react';

const ProgressBar = () => {
  const [progress, setProgress] = useState(100);
  const twoColors = {
    '100%': '#20ACFB',
    '0%': '#1376B3',
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 0) {
          clearInterval(interval);
        }
        return prevProgress - 0.1;
      });
    }, 60000 / 1100);

    return () => {
      clearInterval(interval);
    };
  }, [progress]);

  return (
    <Progress className="position-absolute" percent={progress} showInfo={false} strokeColor={twoColors} />
  );
};

export default ProgressBar;
