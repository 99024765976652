import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SynthesisCard from './SynthesisCard';
import {
  formatDate, getLeakValue, getQualificationValue, getTriplets,
} from '../../../../utils/utils';

const SynthesisCards = ({ currentRecord }) => {
  const {
    fileName,
    timeStamp,
    timeStampCalcul,
    decision,
    qualification,
    decisionPeakSearchGlobal,
    decisionPeakSearchBlock,
    decisionCnn,
  } = currentRecord;

  const qualificationValue = getQualificationValue(qualification);
  const leakValue = getLeakValue(decision);
  const triplets = getTriplets(decisionPeakSearchGlobal, decisionPeakSearchBlock, decisionCnn);
  const { t } = useTranslation();

  const infosCard = [
    { label: t('records.synthesisCard.triplets'), value: triplets },
    { label: t('records.synthesisCard.decision'), value: t(`records.synthesisCard.${leakValue}`) },
    { label: t('records.synthesisCard.qualification'), value: t(`records.synthesisCard.${qualificationValue}`) },
    { label: t('records.synthesisCard.algo'), value: 'PROV8' },
  ];

  const recordCard = [
    { label: t('records.synthesisCard.filename'), value: fileName },
    { label: t('records.synthesisCard.recordDate'), value: formatDate(timeStamp, t('format.dayMonthTime')) },
    { label: t('records.synthesisCard.analysisDate'), value: formatDate(timeStampCalcul * 1000, t('format.dayMonthTime')) },
  ];

  const synthesisCards = [
    { title: t('records.synthesisCard.calculs'), details: infosCard },
    { title: t('records.synthesisCard.data'), details: recordCard },
  ];

  const renderSynthesisCards = () => synthesisCards?.map(({ title, details, mapData }) => (
    <SynthesisCard key={title} title={title} details={details} mapData={mapData} width={350} />
  ));

  return (
    <section className="synthesis-cards">
      {renderSynthesisCards()}
    </section>
  );
};

SynthesisCards.propTypes = {
  currentRecord: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    timeStamp: PropTypes.number.isRequired,
    timeStampCalcul: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    commune: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    decision: PropTypes.number.isRequired,
    qualification: PropTypes.number.isRequired,
    leakFrequency: PropTypes.number.isRequired,
    leakPower: PropTypes.number.isRequired,
    decisionPeakSearchGlobal: PropTypes.number.isRequired,
    decisionPeakSearchBlock: PropTypes.number.isRequired,
    decisionCnn: PropTypes.number.isRequired,
  }),
};

SynthesisCards.defaultProps = {
  currentRecord: null,
};

export default SynthesisCards;
