import { call, put, takeEvery } from 'redux-saga/effects';
import { CANALTYPE, getCanalTypesAction, postCanalTypeAction } from '../actions/canalTypeAction';
import { getCanalTypesService, postCanalTypeService } from '../services/canalTypeService';
import { handleSagaErrorNotification, handleSagaSuccessNotification } from '../utils/responseHandler';
import { isProdEnv } from '../../utils/utils';

export function* getCanalTypes({ payload }) {
  const { token } = payload;
  try {
    const canalTypes = yield call(getCanalTypesService, token);
    const data = canalTypes?.data;

    if (data) {
      yield put(getCanalTypesAction.success(data));
    }
  } catch (err) {
    if (!isProdEnv) {
      handleSagaErrorNotification(err, 'canalType');
    }
    yield put(getCanalTypesAction.failure(err));
  }
}

export function* postCanalType({ payload }) {
  const token = payload[1];
  try {
    const canalTypeResponse = yield call(postCanalTypeService, payload);
    const canalTypes = yield call(getCanalTypesService, token);

    if (canalTypeResponse) {
      const descriptionSuccess = 'shared.notification.canal.successPostType';
      yield put(postCanalTypeAction.success(descriptionSuccess));
      yield put(getCanalTypesAction.success(canalTypes.data));
      yield put(postCanalTypeAction.failure(null));
      handleSagaSuccessNotification(descriptionSuccess);
    }
  } catch (err) {
    handleSagaErrorNotification(err, 'canalType');
    yield put(postCanalTypeAction.failure(err));
  }
}

export function* canalTypesSaga() {
  yield takeEvery(CANALTYPE.FETCH.REQUEST, getCanalTypes);
  yield takeEvery(CANALTYPE.POST.REQUEST, postCanalType);
}
