import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HeaderComponent } from '../SharedStyle';
import UserBlock from './UserBlock';

const HeaderContent = ({ title }) => {
  const { t } = useTranslation();
  return (
    <HeaderComponent className="header-content">
      <div>
        <span className="title">{t(title)}</span>
      </div>
      <UserBlock />
    </HeaderComponent>
  );
};

HeaderContent.propTypes = {
  title: PropTypes.string.isRequired,
};
export default HeaderContent;
