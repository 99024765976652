import PropTypes from 'prop-types';
import MapLeak from '../../map';
import { StyledCard } from '../../SharedStyle';

const SynthesisCard = (({
  title, details, mapData, width,
}) => (
  <StyledCard title={title} width={width}>
    <div>
      {details?.map(({ label, value }) => (
        <p key={label}>{`${label} : ${value}`}</p>
      ))}
    </div>
    { mapData.length > 0 && <MapLeak className="mini-map" data={mapData} isMiniMap /> }
  </StyledCard>
));

SynthesisCard.propTypes = {
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  mapData: PropTypes.arrayOf(PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    timestamp: PropTypes.number.isRequired,
  })),
  details: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SynthesisCard.defaultProps = {
  width: 400,
  mapData: [],
};

export default SynthesisCard;
