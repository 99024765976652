import { Button, Popover } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SafetyCertificateOutlined, CopyOutlined } from '@ant-design/icons';
import TableTemplate from '../TableTemplate';
import FilterColumnSearch from '../FilterColumnSearch';
import {
  formatDate,
  getQualificationValue,
  getTriplets,
  renderDecisionAlgo,
  setUniqueList,
  openNotificationInfo,
} from '../../../utils/utils';
import { Context } from '../../../utils/context/Intlcontext';

const RecordsList = ({
  recordsList,
  handleShowChartModal,
  handleShowDrawer,
  typeChart,
}) => {
  const { initialLang } = useContext(Context);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (recordsList) {
      const sortedRecordsList = recordsList.sort((a, b) => b.timeStamp - a.timeStamp);
      sortedRecordsList?.forEach((sound) => {
        const { decisionPeakSearchGlobal, decisionPeakSearchBlock, decisionCnn } = sound;
        data.push({
          key: sound.blockChainHash,
          timestamp: sound.timeStamp,
          triplets: getTriplets(decisionPeakSearchGlobal, decisionPeakSearchBlock, decisionCnn),
          ...sound,
        });
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [recordsList]);

  const renderAgenciesFilter = () => {
    const agenciesFilter = [];
    const agenciesList = setUniqueList(recordsList, 'agencyName');
    agenciesList?.map((agency) => (
      agenciesFilter.push({ text: agency.agencyName ? agency.agencyName : '--', value: agency.agencyName ? agency.agencyName : '--' })
    ));
    return agenciesFilter;
  };

  const handleCopyToClipboard = (blockChainHash) => () => {
    navigator.clipboard.writeText(blockChainHash);
    openNotificationInfo({
      message: t('records.blockchainCopied'),
      placement: 'top',
    });
  };

  const contentPopover = (blockChainHash) => (
    <section className="blockchain-popover">
      <Button
        className="copy-blockchain-btn"
        onClick={handleCopyToClipboard(blockChainHash)}
        icon={<CopyOutlined />}
      />
      <div className="align-center">
        <h3>{t('records.blockchainTitle')}</h3>
        <span>{blockChainHash}</span>
      </div>
    </section>
  );

  const columns = [
    {
      title: t('records.expandable.mobileNumber'),
      key: 'telephoneNumber',
      dataIndex: 'telephoneNumber',
      ...FilterColumnSearch('telephoneNumber'),
      render: (text, record) => (
        <Button onClick={() => handleShowChartModal(record, typeChart)}>
          {text || '--'}
        </Button>
      ),
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
    },
    {
      title: t('records.agency'),
      key: 'agencyName',
      dataIndex: 'agencyName',
      render: (text, record) => <span>{text || record.companyName}</span>,
      responsive: ['md', 'lg'],
      width: 80,
      filters: renderAgenciesFilter(),
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, sound) => {
        const res = sound.agencyName ? sound.agencyName?.startsWith(value) : null;
        return (res);
      },
      sorter: (a, b) => (
        (a.agencyName || a.companyName).localeCompare(b.agencyName || b.companyName)
      ),
      align: 'center',
    },
    {
      title: t('records.town'),
      key: 'commune',
      dataIndex: 'commune',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 80,
      sorter: (a, b) => a.commune.localeCompare(b.commune),
      align: 'center',
    },
    {
      title: t('records.department'),
      key: 'department',
      dataIndex: 'department',
      align: 'center',
      render: (text) => <span>{text}</span>,
      responsive: ['md', 'lg'],
      width: 50,
    },
    {
      title: t('records.date'),
      dataIndex: 'timeStamp',
      key: 'timeStamp',
      render: (timeStamp) => formatDate(timeStamp, t('format.fullDate'), initialLang),
      sorter: (a, b) => a.timeStamp - b.timeStamp,
      width: 80,
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.hour'),
      width: 80,
      dataIndex: 'timeStamp',
      key: 'timeStamp',
      render: (timestamp) => formatDate(timestamp, t('format.time'), initialLang),
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.triplets'),
      width: 60,
      dataIndex: 'triplets',
      key: 'triplets',
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.decision'),
      width: 60,
      dataIndex: 'decision',
      key: 'decision',
      render: (text) => renderDecisionAlgo(text, t),
      responsive: ['md', 'lg'],
      align: 'center',
    },
    {
      title: t('records.qualification'),
      width: 80,
      dataIndex: 'qualification',
      key: 'qualification',
      responsive: ['md', 'lg'],
      align: 'center',
      render: (_, record) => {
        const qualificationValue = getQualificationValue(record.qualification);
        return <span>{t(`records.${qualificationValue}`)}</span>;
      },
    },
    {
      title: t('records.blockchain'),
      dataIndex: 'blockChainHash',
      key: 'blockChainHash',
      render: (text) => (
        <Popover content={contentPopover(text)} trigger="click">
          <Button icon={<SafetyCertificateOutlined />} />
        </Popover>
      ),
      responsive: ['md', 'lg'],
      width: 80,
      align: 'center',
    },
    {
      title: t('records.synthesis'),
      dataIndex: 'synthesis',
      key: 'synthesis',
      render: (_, record) => (
        <Button onClick={() => handleShowDrawer(record)}>
          {t('records.infos')}
        </Button>
      ),
      width: 80,
      responsive: ['md', 'lg'],
      align: 'center',
    },

  ];

  return (
    <TableTemplate
      className="records-list"
      columns={columns}
      data={tableData}
      typeList="fullHeight"
    />
  );
};

RecordsList.propTypes = {
  typeChart: PropTypes.string.isRequired,
  recordsList: PropTypes.arrayOf(PropTypes.shape({
    telephoneNumber: PropTypes.string.isRequired,
    androidId: PropTypes.string.isRequired,
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    fileName: PropTypes.string.isRequired,
    agencyName: PropTypes.string.isRequired,
  })).isRequired,
  handleShowChartModal: PropTypes.func.isRequired,
  handleShowDrawer: PropTypes.func.isRequired,
};

export default RecordsList;
