import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import AgenciesChart from './AgenciesChart';

const Charts = () => {
  const { agenciesDetails } = useSelector((state) => state.agencies);

  const renderAgenciesCharts = () => {
    if (agenciesDetails) {
      const { chartData } = agenciesDetails;
      return chartData?.map((agency) => (
        <AgenciesChart
          key={agency.name}
          chartData={agency.chartData || []}
          name={agency.name}
        />
      ));
    }
    return null;
  };
  return (
    <article className="chart-cards">
      {agenciesDetails ? renderAgenciesCharts() : <Spin />}
    </article>
  );
};

export default Charts;
