import { LOGIN, LOGOUT } from '../actions/loginAction';

const INITIAL_STATE = { user: null, loading: false };

const loginReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN.POST.REQUEST:
      return { ...state, loading: true };
    case LOGIN.POST.SUCCESS:
      return { ...state, user: payload, loading: false };
    case LOGIN.POST.FAILURE:
      return { ...state, loading: false };
    case LOGOUT.POST.REQUEST:
      return { ...state, loading: true };
    case LOGOUT.POST.SUCCESS:
      return INITIAL_STATE;
    case LOGOUT.POST.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default loginReducer;
