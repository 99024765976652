import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';

const CustomDrawer = ({
  className, children, width, title, setIsOpenDrawer, isOpenDrawer, placement, height,
}) => {
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };
  return (
    <Drawer
      className={className}
      placement={placement}
      title={title}
      width={width}
      height={height}
      open={isOpenDrawer}
      onClose={handleCloseDrawer}
    >
      {children}
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  title: PropTypes.shape({}).isRequired,
  placement: PropTypes.string,
  setIsOpenDrawer: PropTypes.func.isRequired,
  isOpenDrawer: PropTypes.bool,
};

CustomDrawer.defaultProps = {
  className: '',
  height: 400,
  isOpenDrawer: false,
  placement: 'right',
  width: '',
};
export default CustomDrawer;
