import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';

export const COUNTANALYSIS = createRequestTypes('COUNTANALYSIS', [FETCH]);
export const COUNTLEAK = createRequestTypes('COUNTLEAK', [FETCH]);
export const COUNTNOLEAK = createRequestTypes('COUNTNOLEAK', [FETCH]);
export const COUNTNOSIGNAL = createRequestTypes('COUNTNOSIGNAL', [FETCH]);

export const getCountAnalysisAction = {
  success: (data) => action(COUNTANALYSIS.FETCH.SUCCESS, data),
  failure: (error) => action(COUNTANALYSIS.FETCH.FAILURE, error),
};

export const getCountLeakAction = {
  success: (data) => action(COUNTLEAK.FETCH.SUCCESS, data),
  failure: (error) => action(COUNTLEAK.FETCH.FAILURE, error),
};

export const getCountNoLeakAction = {
  success: (data) => action(COUNTNOLEAK.FETCH.SUCCESS, data),
  failure: (error) => action(COUNTNOLEAK.FETCH.FAILURE, error),
};

export const getCountNoSignalAction = {
  success: (data) => action(COUNTNOSIGNAL.FETCH.SUCCESS, data),
  failure: (error) => action(COUNTNOSIGNAL.FETCH.FAILURE, error),
};
