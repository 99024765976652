import api from '../utils/query';

const baseUrl = 'soundRecord';

export const getRecordsService = (token) => api.get(baseUrl, token);

export const getRecordsByUserService = (token) => {
  const apiUrl = `${baseUrl}/user`;
  return api.get(apiUrl, token);
};
