import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
import TimelineStatus from './TimelineStatus';

const OrderStatus = ({ currentStatus, currentOrder, setCurrentStatus }) => {
  const { orders } = useSelector((state) => state.orders);

  return (
    <>
      <Divider />
      <TimelineStatus
        currentStatus={currentStatus}
        orders={orders}
        currentOrder={currentOrder}
        setCurrentStatus={setCurrentStatus}
      />
    </>
  );
};

OrderStatus.propTypes = {
  currentStatus: PropTypes.shape({
    status: PropTypes.number,
    timestamp: PropTypes.number,
  }),
  currentOrder: PropTypes.string.isRequired,
  setCurrentStatus: PropTypes.func.isRequired,
};

OrderStatus.defaultProps = {
  currentStatus: null,
};

export default OrderStatus;
