import api from '../utils/query';

const baseUrl = 'company';

export const getCompaniesService = (token) => api.get(baseUrl, token);

export const getCompaniesByUserService = (token) => {
  const apiUrl = `${baseUrl}/user`;
  return api.get(apiUrl, token);
};

export const postCompanyService = (data) => api.create(baseUrl, data);

export const patchCompanyService = (data) => {
  const { id } = data;
  const apiUrl = `${baseUrl}/${id}`;
  return api.update(apiUrl, data);
};
