import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TagOutlined } from '@ant-design/icons';
import { renderTags } from '../../utils/utils';
import EntityListTemplate from '../shared/list/EntityListTemplate';
import { blueAzurColor } from '../../assets/styles/colors';

const CompaniesList = () => {
  const { companies } = useSelector((state) => state.companies);
  const { distributors } = useSelector((state) => state.distributors);
  const { t } = useTranslation();

  const companyNameColumn = {
    title: t('dashboard.distributors.associatedCompanies'),
    dataIndex: 'associatedCompanies',
    key: 'associatedCompanies',
    render: (text) => {
      const associatedCompanies = companies?.filter((company) => company.distributorId === text);
      return associatedCompanies?.map((company) => {
        const { id, name } = company;
        return renderTags(
          id,
          <TagOutlined className="button-reset" />,
          name,
          blueAzurColor,
        );
      });
    },
    responsive: ['md', 'lg'],
    width: 90,
  };

  return (
    <EntityListTemplate
      type="distributors"
      entity={distributors}
      addColumn={companyNameColumn}
    />
  );
};
export default CompaniesList;
