import React, { useContext } from 'react';
import { Select } from 'antd';
import i18n from '../../../utils/i18n';
import { Context } from '../../../utils/context/Intlcontext';
import { optionsLanguages } from '../../../utils/utils';

const { Option } = Select;
const { changeLanguage } = i18n;

const LanguageSwitch = () => {
  const context = useContext(Context);
  const lang = context?.initialLang;

  const renderOptions = () => optionsLanguages?.map((option) => (
    <Option key={option.id} value={option.value}>
      {option.label}
    </Option>
  ));

  const handleSwitchLanguage = (value) => {
    if (value) {
      const { setInitialLang } = context;
      setInitialLang(value);
      changeLanguage(value);
    }
  };

  return (
    <Select
      className="language-switch"
      defaultValue={lang}
      onChange={(value) => handleSwitchLanguage(value)}
      bordered={false}
    >
      {renderOptions()}
    </Select>
  );
};

export default LanguageSwitch;
