import React from 'react';
import {
  ArcElement, Chart, Legend, Tooltip,
} from 'chart.js';
import { PropTypes } from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

Chart.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ dataChart, labelsData, color }) => {
  const color1 = [
    'rgba(55, 206, 86, 0.5)',
    'rgba(154, 162, 235, 0.5)',
    'rgba(25, 99, 132, 0.5)',
  ];
  const color2 = [
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
  ];
  const color3 = [
    'rgba(254, 12, 25, 0.5)',
    'rgba(25, 199, 132, 0.5)',
    'rgba(255, 206, 186, 0.5)',
  ];

  const getColor = () => {
    if (color === 1) {
      return color1;
    }
    if (color === 2) {
      return color2;
    }
    return color3;
  };

  const data = {
    labels: labelsData,
    datasets: [
      {
        label: 'total',
        data: dataChart,
        backgroundColor: getColor(),
        borderColor: getColor(),
        borderWidth: 1,
      },
    ],
  };
  return (
    <Doughnut data={data} />
  );
};

DoughnutChart.propTypes = {
  dataChart: PropTypes.arrayOf(PropTypes.number).isRequired,
  labelsData: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.number.isRequired,
};

export default DoughnutChart;
