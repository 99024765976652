import { put, takeEvery } from 'redux-saga/effects';
import { MONITOR, getMonitorAction } from '../actions/monitoringAction';
import { handleSagaErrorNotification } from '../utils/responseHandler';
import { isProdEnv } from '../../utils/utils';

function findNoSignal(soundsList, recordsList) {
  const uniqueSounds = soundsList?.map((item) => item.timestamp);
  const uniqueRecords = recordsList?.map((item) => item.timestamp);
  const uniqueTimestampRecords = new Set(uniqueRecords);

  const filteredUniqueTimestamp = [...uniqueTimestampRecords]
    .filter((timestamp) => !uniqueSounds.includes(timestamp));
  return filteredUniqueTimestamp;
}

const getQualificationChart = (soundsList) => {
  const qualificationOK = soundsList.filter((sound) => {
    const noLeakSounds = sound.decision === 0 || sound.decision === 4;
    const noLeakQualification = sound.qualification === 0;
    return noLeakQualification === noLeakSounds;
  });
  const qualificationNOK = soundsList.filter((sound) => sound.qualification === 1);
  const qualificationNull = soundsList.filter((sound) => {
    const noQualification = sound.qualification === null || sound.qualification === 2;
    return noQualification;
  });
  const qualificationSounds = [
    qualificationOK.length,
    qualificationNOK.length,
    qualificationNull.length,
  ];
  return qualificationSounds;
};

export function* getMonitoring({ payload }) {
  let totalLeak = 0;
  let totalNoLeak = 0;
  let totalNoSignal = 0;
  let totalAnalysis = 0;
  let qualificationChart = [];
  if (payload) {
    const { soundsList, recordsList } = payload;

    if (soundsList) {
      const noSignal = findNoSignal(soundsList, recordsList);

      const leak = soundsList.filter((sound) => sound.decision === 1);
      const noLeak = soundsList.filter((sound) => sound.decision === 0);
      const doubtLeak = soundsList.filter((sound) => sound.decision === 4);
      totalLeak = leak.length;
      totalNoLeak = noLeak.length + doubtLeak.length;
      totalNoSignal = noSignal.length;
      totalAnalysis = soundsList.length;
      qualificationChart = getQualificationChart(soundsList);
    }
  }
  try {
    yield put(getMonitorAction.success({
      leak: totalLeak,
      noLeak: totalNoLeak,
      noSignal: totalNoSignal,
      analysis: totalAnalysis,
      qualification: qualificationChart,
    }));
  } catch (error) {
    if (!isProdEnv) {
      handleSagaErrorNotification(error, 'monitoring');
    }
    yield put(getMonitorAction.failure(error));
  }
}

export function* getMonitoringSaga() {
  yield takeEvery(MONITOR.FETCH.REQUEST, getMonitoring);
}
