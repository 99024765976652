import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';

export const RECORDS = createRequestTypes('RECORDS', [FETCH]);

export const getRecordsAction = {
  request: (payload) => action(RECORDS.FETCH.REQUEST, payload),
  success: (data) => action(RECORDS.FETCH.SUCCESS, data),
  failure: (error) => action(RECORDS.FETCH.FAILURE, error),
  error: (error) => action(RECORDS.FETCH.ERROR, error),
};
