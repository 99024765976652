import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardList from '../shared/SharedStyle';
import TableTemplate from '../shared/TableTemplate';
import { getPhonesNumberAction } from '../../business/actions/mobileAction';
import HeaderList from './agenciesDetails/HeaderList';
import { renderCalibrationStatus } from '../../utils/utils';

const MobilesList = () => {
  const { agencies } = useSelector((state) => state.agencies);
  const { mobiles } = useSelector((state) => state.mobiles);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderNumbersFilter = () => {
    const numberList = [];
    tableData?.map((number) => {
      numberList.push({ text: number.number, value: number.number });
      return null;
    });
    return numberList;
  };

  const renderData = () => {
    const data = [];
    mobiles?.map((mobile) => {
      data.push({
        key: mobile.id,
        number: mobile.telephoneNumber,
        model: mobile.model,
        version: mobile.systemVersion,
        calibration: mobile.calibrationSoundCount,
        agency: mobile.agencyId,
      });
      return null;
    });
    setTableData(data);
  };

  useEffect(() => {
    renderData();
  }, [mobiles]);

  const columns = [
    {
      title: t('dashboard.mobiles.number'),
      dataIndex: 'number',
      key: 'number',
      filters: renderNumbersFilter(),
      filterMode: 'tree',
      filterSearch: true,
      render: (text) => text,
      onFilter: (value, record) => {
        const res = record.number ? record.number?.startsWith(value) : null;
        return (res);
      },
      responsive: ['md', 'lg'],
      align: 'center',
      width: 60,
    },
    {
      title: t('dashboard.mobiles.model'),
      dataIndex: 'model',
      key: 'model',
      responsive: ['md', 'lg'],
      align: 'center',
      width: 50,
    },
    {
      title: t('dashboard.mobiles.version'),
      dataIndex: 'version',
      key: 'version',
      responsive: ['md', 'lg'],
      align: 'center',
      width: 45,
    },
    {
      title: t('dashboard.mobiles.calibration'),
      dataIndex: 'calibration',
      key: 'calibration',
      render: renderCalibrationStatus,
      responsive: ['md', 'lg'],
      align: 'center',
      width: 50,
    },
    {
      title: t('dashboard.mobiles.agency'),
      dataIndex: 'agency',
      key: 'agency',
      render: (text) => agencies?.map((agency) => agency.id === text && agency.name),
      responsive: ['md', 'lg'],
      align: 'center',
      width: 60,
    },
  ];

  return (
    <DashboardList width="650px">
      <HeaderList
        name={t('dashboard.mobiles.title')}
        stateToUpdate={getPhonesNumberAction}
        visibleUpdateData
      />
      <TableTemplate columns={columns} data={tableData} />
    </DashboardList>

  );
};

export default MobilesList;
