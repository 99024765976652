import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import DashboardList from '../SharedStyle';

const SkeletonList = ({ width, rows }) => (
  <DashboardList width={width}>
    <Skeleton active paragraph={{ rows }} />
  </DashboardList>
);

SkeletonList.propTypes = {
  width: PropTypes.string,
  rows: PropTypes.number,
};

SkeletonList.defaultProps = {
  width: '800',
  rows: 4,
};

export default SkeletonList;
