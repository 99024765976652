import { CANALWIDTH } from '../actions/canalWidthAction';

const INITIAL_STATE = { canalWidths: null, message: null, loading: false };

const canalWidthReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CANALWIDTH.FETCH.REQUEST:
      return { ...state, loading: true };
    case CANALWIDTH.FETCH.SUCCESS:
      return { ...state, canalWidths: payload, loading: false };
    case CANALWIDTH.FETCH.FAILURE:
      return { ...state, loading: false };
    case CANALWIDTH.POST.REQUEST:
      return { ...state, loading: true };
    case CANALWIDTH.POST.SUCCESS:
      return { ...state, message: payload, loading: false };
    case CANALWIDTH.POST.FAILURE:
      return { ...state, message: payload, loading: false };
    default:
      return state;
  }
};

export default canalWidthReducer;
