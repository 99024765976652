import { all } from 'redux-saga/effects';
import { getRecordsSaga } from './recordsSaga';
import { getSoundsSaga } from './soundsSaga';
import { agenciesSaga } from './agencySaga';
import { phoneNumberSaga } from './mobileSaga';
import { getAnalysisSaga, getAnalysisDetailsSaga } from './analysisSaga';
import { getMonitoringSaga } from './monitoringSaga';
import { companiesSaga } from './companySaga';
import { postAuthSaga } from './authSaga';
import logoutSaga from './loginSaga';
import { canalTypesSaga } from './canalTypeSaga';
import { canalWidthsSaga } from './canalWidthSaga';
import { canalSurfacesSaga } from './canalSurfaceSaga';
import { ordersSaga } from './orderSaga';
import { distributorsSaga } from './distributorSaga';
import { usersSaga } from './userSaga';

export default function* rootSaga() {
  yield all([
    postAuthSaga(),
    logoutSaga(),
    getRecordsSaga(),
    getSoundsSaga(),
    getAnalysisSaga(),
    getAnalysisDetailsSaga(),
    agenciesSaga(),
    distributorsSaga(),
    companiesSaga(),
    phoneNumberSaga(),
    getMonitoringSaga(),
    canalTypesSaga(),
    canalWidthsSaga(),
    canalSurfacesSaga(),
    ordersSaga(),
    usersSaga(),
  ]);
}
