import React from 'react';
import { Button } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const PrintButton = () => {
  const { t } = useTranslation();
  const handlePrint = () => {
    window.print();
  };
  return (
    <Button
      icon={<PrinterOutlined />}
      onClick={handlePrint}
    >
      {t('shared.print.title')}
    </Button>
  );
};

export default PrintButton;
