import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';

export const USERS = createRequestTypes('USER', [FETCH]);

export const getUsersAction = {
  request: (data) => action(USERS.FETCH.REQUEST, data),
  success: (data) => action(USERS.FETCH.SUCCESS, data),
  failure: (error) => action(USERS.FETCH.FAILURE, error),
};
