import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { getRoleFromToken } from '../utils';

export const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
  const { token } = useSelector((state) => state.auth);

  let decodedToken = null;
  let role = null;

  if (token?.access_token) {
    decodedToken = jwtDecode(token.access_token);
    role = getRoleFromToken(decodedToken);
  }

  const contextValue = useMemo(() => ({
    token: token?.access_token,
    refreshToken: token?.refresh_token,
    role,
  }), [token, role]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContextProvider;
