import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormTemplate from './FormTemplate';
import { regexCanal } from '../../../utils/utils';
import { getCanalTypesAction, postCanalTypeAction } from '../../../business/actions/canalTypeAction';
import { getCanalWidthsAction, postCanalWidthAction } from '../../../business/actions/canalWidthAction';
import { getCanalSurfacesAction, postCanalSurfaceAction } from '../../../business/actions/canalSurfaceAction';
import { AuthContext } from '../../../utils/context/AuthContext';

const CanalForm = () => {
  const { canalTypes } = useSelector((state) => state.canalType);
  const { canalWidths } = useSelector((state) => state.canalWidth);
  const { canalSurfaces } = useSelector((state) => state.canalSurface);
  const [isDisabledCanalTypeBtn, setIsDisabledCanalTypeBtn] = useState(true);
  const [isDisabledCanalDiameterBtn, setIsDisabledCanalDiameterBtn] = useState(true);
  const [isDisabledCanalSupportBtn, setIsDisabledCanalSupportBtn] = useState(true);
  const { token, role } = useContext(AuthContext);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCanalTypesAction.request({ token, role }));
    dispatch(getCanalWidthsAction.request({ token, role }));
    dispatch(getCanalSurfacesAction.request({ token, role }));
  }, []);

  const handlePostCanal = (fieldName, actionCreator, setIsDisabledBtn) => () => {
    let value = form.getFieldValue(fieldName);
    value = value.trim().toLowerCase();
    dispatch(actionCreator.request([value, token]));
    form.resetFields([fieldName]);
    setIsDisabledBtn(true);
  };

  const handlePostCanalType = handlePostCanal('canalType', postCanalTypeAction, setIsDisabledCanalTypeBtn);
  const handlePostCanalWidth = handlePostCanal('canalWidth', postCanalWidthAction, setIsDisabledCanalDiameterBtn);
  const handlePostCanalSurface = handlePostCanal('canalSurface', postCanalSurfaceAction, setIsDisabledCanalSupportBtn);

  const variables = [
    {
      label: t('settings.canal.type'),
      name: 'canalType',
      type: 'addItem',
      placeholder: t('settings.canal.typePlaceholder'),
      minLength: 2,
      maxLength: 250,
      validateDebounce: 500,
      isDisabledBtn: isDisabledCanalTypeBtn,
      isDisabledValidateTrigger: true,
      onClick: handlePostCanalType,
      rules: [
        {
          message: t('settings.canal.rules'),
          pattern: regexCanal,
        },
        () => ({
          validator(_, value) {
            const isCanalTypeAlreadyExists = canalTypes.some(
              (canalType) => canalType.name.toLowerCase() === value.trim().toLowerCase(),
            );

            if (isCanalTypeAlreadyExists) {
              setIsDisabledCanalTypeBtn(true);
              return Promise.reject(new Error(t('settings.canal.typeAlreadyExist')));
            }

            if (value === '') {
              setIsDisabledCanalTypeBtn(true);
              return Promise.reject(t('settings.canal.rules'));
            }

            const isValidField = regexCanal.test(value);
            setIsDisabledCanalTypeBtn(!isValidField);

            return isValidField
              ? Promise.resolve()
              : Promise.reject(new Error());
          },
        }),
      ],
    },
    {
      label: t('settings.canal.width'),
      name: 'canalWidth',
      type: 'addItem',
      placeholder: t('settings.canal.widthPlaceholder'),
      minLength: 2,
      maxLength: 250,
      validateDebounce: 500,
      isDisabledBtn: isDisabledCanalDiameterBtn,
      isDisabledValidateTrigger: true,
      onClick: handlePostCanalWidth,
      rules: [
        {
          message: t('settings.canal.rules'),
          pattern: regexCanal,
        },
        () => ({
          validator(_, value) {
            const isCanalWidthAlreadyExists = canalWidths.some(
              (canalWidth) => canalWidth.name.toLowerCase() === value.trim().toLowerCase(),
            );

            if (isCanalWidthAlreadyExists) {
              setIsDisabledCanalDiameterBtn(true);
              return Promise.reject(new Error(t('settings.canal.widthAlreadyExist')));
            }

            if (value === '') {
              setIsDisabledCanalDiameterBtn(true);
              return Promise.reject(t('settings.canal.rules'));
            }

            const isValidField = regexCanal.test(value);
            setIsDisabledCanalDiameterBtn(!isValidField);

            return isValidField
              ? Promise.resolve()
              : Promise.reject(new Error());
          },
        }),
      ],
    },
    {
      label: t('settings.canal.surface'),
      name: 'canalSurface',
      type: 'addItem',
      placeholder: t('settings.canal.surfacePlaceholder'),
      minLength: 2,
      maxLength: 250,
      validateDebounce: 500,
      isDisabledBtn: isDisabledCanalSupportBtn,
      isDisabledValidateTrigger: true,
      onClick: handlePostCanalSurface,
      rules: [
        {
          message: t('settings.canal.rules'),
          pattern: regexCanal,
        },
        () => ({
          validator(_, value) {
            const isCanalSurfaceAlreadyExists = canalSurfaces.some(
              (canalSurface) => canalSurface.name.toLowerCase() === value.trim().toLowerCase(),
            );

            if (isCanalSurfaceAlreadyExists) {
              setIsDisabledCanalSupportBtn(true);
              return Promise.reject(new Error(t('settings.canal.surfaceAlreadyExist')));
            }

            if (value === '') {
              setIsDisabledCanalSupportBtn(true);
              return Promise.reject(t('settings.canal.rules'));
            }

            const isValidField = regexCanal.test(value);
            setIsDisabledCanalSupportBtn(!isValidField);

            return isValidField
              ? Promise.resolve()
              : Promise.reject(new Error());
          },
        }),
      ],
    },
  ];

  return (
    <FormTemplate
      submitDisabled
      nameForm="canalForm"
      className="settings-form"
      useForm={form}
      variables={variables}
    />
  );
};

export default CanalForm;
