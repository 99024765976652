import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import FormTemplate from './FormTemplate';
import { getAgenciesAction } from '../../../business/actions/agencyAction';
import { getCompaniesAction } from '../../../business/actions/companyAction';
import { getDistributorsAction } from '../../../business/actions/distributorAction';
import {
  regexEmail,
  isProdEnv,
  preventInvalidNumberInput,
} from '../../../utils/utils';
import { AuthContext } from '../../../utils/context/AuthContext';
import { getUsersAction } from '../../../business/actions/userAction';

const EntityFormTemplate = ({
  postAction, type, className, companyField = [], distributorField = [],
}) => {
  const { distributors } = useSelector((state) => state.distributors);
  const { agencies } = useSelector((state) => state.agencies);
  const { companies } = useSelector((state) => state.companies);
  const { users } = useSelector((state) => state.users);
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState(null);
  const [isDisabledEmailBtn, setIsDisabledEmailBtn] = useState(true);
  const { token, role } = useContext(AuthContext);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (type === 'agency') dispatch(getAgenciesAction.request({ showError: !isProdEnv, token, role }));
    if (type === 'company' || type === 'agency') dispatch(getCompaniesAction.request({ showError: !isProdEnv, token, role }));
    if (type === 'distributor' || type === 'company') dispatch(getDistributorsAction.request({ showError: !isProdEnv, token, role }));
    dispatch(getUsersAction.request({ showError: !isProdEnv, token }));
  }, []);

  const onFinish = (data) => {
    const {
      name, hour, minute, company, distributor,
    } = data;
    const isDistributor = type === 'empty';
    const isCompany = type === 'company';

    const companyData = company ? { companyId: company } : {};
    const timeData = !isDistributor ? { dailyMailHour: hour, dailyMailMinute: minute } : {};
    const distributorData = isCompany ? { distributorId: distributor } : {};

    const payload = {
      name,
      emails,
      token,
      role,
      ...companyData,
      ...timeData,
      ...distributorData,
    };

    dispatch(postAction.request(payload));
    form.resetFields();
    setEmails([]);
    return Promise.resolve();
  };

  const handleAddEmail = () => {
    if (newEmail) {
      setEmails([...emails, newEmail]);
      setNewEmail(null);
      setIsDisabledEmailBtn(true);
      form.resetFields(['emails']);
    }
  };

  const checkExistenceEmail = (value) => {
    if (!value) {
      setNewEmail(null);
      form.resetFields(['emails']);
      setIsDisabledEmailBtn(true);
      return Promise.resolve();
    }

    const isValideEmail = regexEmail.test(value);
    const isEmailTagExist = emails.some(
      (email) => email.toLowerCase() === value.toLowerCase(),
    );
    const isEmailExistInDB = users?.some(
      (user) => user.email.toLowerCase() === value.toLowerCase(),
    );

    if (!isValideEmail) {
      setIsDisabledEmailBtn(true);
      return Promise.reject(t('settings.entity.emailHelp'));
    }

    if (isEmailTagExist || isEmailExistInDB) {
      setIsDisabledEmailBtn(true);
      return Promise.reject(t('settings.entity.emailError'));
    }

    setIsDisabledEmailBtn(false);
    setNewEmail(value);
    return Promise.resolve();
  };

  const checkExistenceName = (value) => {
    const typeCollections = {
      agency: agencies,
      company: companies,
      distributor: distributors,
    };

    const collection = typeCollections[type];

    const isExist = collection?.some(
      (item) => item.name.toLowerCase() === value?.toLowerCase(),
    );

    return isExist ? Promise.reject(t(`settings.${type}.nameError`)) : Promise.resolve();
  };

  const commonVariables = [
    ...distributorField,
    {
      label: t('settings.entity.name'),
      name: 'name',
      rules: [
        {
          required: true,
          message: t(`settings.${type}.nameHelp`),
        },
        () => ({
          validator(_, value) {
            return checkExistenceName(value);
          },
        }),
      ],
      placeholder: t(`settings.${type}.placeholderName`),
    },
    ...companyField,
    {
      label: t('settings.entity.emails'),
      name: 'emails',
      type: 'addItem',
      list: emails,
      isDisabledBtn: isDisabledEmailBtn,
      setList: setEmails,
      onClick: handleAddEmail,
      rules: [
        () => ({
          validator(_, value) {
            return checkExistenceEmail(value);
          },
        }),
      ],
      placeholder: t('settings.entity.placeholderEmail'),
    },
  ];

  const hourVariables = [
    {
      label: t('settings.entity.hour'),
      name: 'hour',
      type: 'number',
      min: 0,
      max: 23,
      onBeforeInput: (event) => preventInvalidNumberInput(event, 0, 23),
      maxLength: 2,
      rules: [
        {
          required: true,
          message: t('settings.entity.hourHelp'),
        },
      ],
      placeholder: t('settings.entity.placeholderHour'),
    },
    {
      label: t('settings.entity.minute'),
      name: 'minute',
      type: 'number',
      min: 0,
      max: 59,
      onBeforeInput: (event) => preventInvalidNumberInput(event, 0, 59),
      maxLength: 2,
      rules: [
        {
          required: true,
          message: t('settings.entity.minuteHelp'),
        },
      ],
      placeholder: t('settings.entity.placeholderMinute'),
    },
  ];

  const variables = type === 'empty'
    ? commonVariables
    : [...commonVariables, ...hourVariables];

  return (
    <FormTemplate
      nameForm="entity-form-template"
      className={`settings-form ${className}`}
      useForm={form}
      variables={variables}
      buttonValidText={t('shared.formTemplate.submit')}
      onFinishApiCall={onFinish}
    />
  );
};

EntityFormTemplate.propTypes = {
  postAction: PropTypes.shape({
    request: PropTypes.func.isRequired,
  }).isRequired,
  companyField: PropTypes.arrayOf(PropTypes.shape({})),
  distributorField: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

EntityFormTemplate.defaultProps = {
  className: '',
  companyField: [],
  distributorField: [],
};

export default EntityFormTemplate;
