import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';

export const SOUNDS = createRequestTypes('SOUNDS', [FETCH]);

export const getSoundsAction = {
  request: (payload) => action(SOUNDS.FETCH.REQUEST, payload),
  success: (data) => action(SOUNDS.FETCH.SUCCESS, data),
  failure: (error) => action(SOUNDS.FETCH.FAILURE, error),
};
