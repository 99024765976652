import React from 'react';
import { Container, Message } from './SharedStyle';

const NotFound = () => (
  <Container>
    <Message>404 - Page Not Found</Message>
  </Container>
);

export default NotFound;
