import {
  COUNTANALYSIS, COUNTLEAK, COUNTNOLEAK, COUNTNOSIGNAL,
} from '../actions/indicatorAction';

const INITIAL_STATE = {
  countAnalysis: null, countLeak: null, countNoLeak: null, countNoSignal: null, loading: false,
};

const indicatorReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case COUNTANALYSIS.FETCH.SUCCESS:
      return { ...state, countAnalysis: payload, loading: false };
    case COUNTANALYSIS.FETCH.ERROR:
      return { ...state, countAnalysis: payload, loading: false };
    case COUNTLEAK.FETCH.SUCCESS:
      return { ...state, countLeak: payload, loading: false };
    case COUNTLEAK.FETCH.ERROR:
      return { ...state, countLeak: payload, loading: false };
    case COUNTLEAK.FETCH.FAILURE:
      return { ...state, msg: payload, loading: false };
    case COUNTNOLEAK.FETCH.SUCCESS:
      return { ...state, countNoLeak: payload, loading: false };
    case COUNTNOLEAK.FETCH.ERROR:
      return { ...state, countNoLeak: payload, loading: false };
    case COUNTNOLEAK.FETCH.FAILURE:
      return { ...state, msg: payload, loading: false };
    case COUNTNOSIGNAL.FETCH.SUCCESS:
      return { ...state, countNoSignal: payload, loading: false };
    case COUNTNOSIGNAL.FETCH.ERROR:
      return { ...state, countNoSignal: payload, loading: false };
    case COUNTNOSIGNAL.FETCH.FAILURE:
      return { ...state, msg: payload, loading: false };
    default:
      return state;
  }
};

export default indicatorReducer;
