import api from '../utils/query';

const baseUrl = 'order';

export const getOrdersService = async (token) => api.get(baseUrl, token);

export const getOrdersByUserService = async (token) => {
  const apiUrl = `${baseUrl}/user`;
  return api.get(apiUrl, token);
};

export const postOrderService = async (payload) => api.create(baseUrl, payload);

export const patchOrderDetailsService = async (data) => {
  const { id } = data;
  const apiUrl = `order/details/${id}`;
  return api.update(apiUrl, data);
};

export const patchOrderStatusService = async (data) => {
  const { id } = data;
  const apiUrl = `order/status/${id}`;
  return api.update(apiUrl, data);
};
