import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';

export const DISTRIBUTORS = createRequestTypes('DISTRIBUTORS', [FETCH]);
export const DISTRIBUTOR = createRequestTypes('DISTRIBUTOR', [POST, PATCH]);

export const getDistributorsAction = {
  request: (payload) => action(DISTRIBUTORS.FETCH.REQUEST, payload),
  success: (data) => action(DISTRIBUTORS.FETCH.SUCCESS, data),
  failure: (error) => action(DISTRIBUTORS.FETCH.FAILURE, error),
};

export const postDistributorAction = {
  request: (payload) => action(DISTRIBUTOR.POST.REQUEST, payload),
  success: (data) => action(DISTRIBUTOR.POST.SUCCESS, data),
  failure: (error) => action(DISTRIBUTOR.POST.FAILURE, error),
};

export const patchDistributorAction = {
  request: (payload) => action(DISTRIBUTOR.PATCH.REQUEST, payload),
  success: (data) => action(DISTRIBUTOR.PATCH.SUCCESS, data),
  failure: (error) => action(DISTRIBUTOR.PATCH.FAILURE, error),
};
