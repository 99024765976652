import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

const ActionButton = ({
  title, icon, className, disabled, onClick,
}) => (
  <Tooltip title={title}>
    <Button
      disabled={disabled}
      className={`button-reset ${className}`}
      icon={icon}
      onClick={onClick}
    />
  </Tooltip>
);

ActionButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

ActionButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => {},
};

export default ActionButton;
