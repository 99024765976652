import api from '../utils/query';

const baseUrl = 'list_mobile';

export const getPhonesNumberService = (token) => {
  const apiUrl = `${baseUrl}/all`;
  return api.get(apiUrl, token);
};

export const getPhonesNumberByUserService = (token) => {
  const apiUrl = `${baseUrl}/user`;
  return api.get(apiUrl, token);
};

export const getPhoneNumberService = (telephoneNumber, token) => {
  const apiUrl = `${baseUrl}/phone_number/${telephoneNumber}`;
  return api.get(apiUrl, token);
};

export const patchPhoneNumberService = (data) => {
  const { id } = data;
  const apiUrl = `${baseUrl}/${id}`;
  return api.update(apiUrl, data);
};

export const patchPhoneNumberStatusService = (data) => {
  const { id } = data;
  const apiUrl = `${baseUrl}/status/${id}`;
  return api.update(apiUrl, data, 'mobileStatus');
};

export const postMobileService = (data) => api.create(baseUrl, data);
