import { combineReducers } from 'redux';
import recordsReducer from './recordsReducer';
import soundsReducer from './soundsReducer';
import agencyReducer from './agencyReducer';
import companyReducer from './companyReducer';
import mobileReducer from './mobileReducer';
import indicatorReducer from './indicatorReducer';
import monitoringReducer from './monitoringReducer';
import authReducer from './authReducer';
import loginReducer from './loginReducer';
import analysisReducer from './analysisReducer';
import canalTypeReducer from './canalTypeReducer';
import canalWidthReducer from './canalWidthReducer';
import canalSurfaceReducer from './canalSurfaceReducer';
import distributorReducer from './distributorReducer';
import orderReducer from './orderReducer';
import userReducer from './userReducer';

export const appReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  rawRecords: recordsReducer,
  sounds: soundsReducer,
  analysis: analysisReducer,
  distributors: distributorReducer,
  agencies: agencyReducer,
  companies: companyReducer,
  mobiles: mobileReducer,
  indicators: indicatorReducer,
  monitoring: monitoringReducer,
  canalType: canalTypeReducer,
  canalWidth: canalWidthReducer,
  canalSurface: canalSurfaceReducer,
  orders: orderReducer,
  users: userReducer,
});

const initialState = appReducer(undefined, {});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_POST_SUCCESS') {
    return initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
