import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

const HeaderDrawer = ({
  title,
  showCSVLink,
  csvData,
  getCsvFileName,
}) => {
  const { t } = useTranslation();
  return (
    <div className="header-drawer">
      <h3>{title}</h3>
      { showCSVLink && (
      <CSVLink className="margin-bottom-medium" data={csvData} filename={getCsvFileName()}>
        {t('dashboard.mobileDetails.downloadCSV')}
      </CSVLink>
      )}
    </div>
  );
};

HeaderDrawer.propTypes = {
  children: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  showCSVLink: PropTypes.bool,
  csvData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
  getCsvFileName: PropTypes.func,
};

HeaderDrawer.defaultProps = {
  children: null,
  showCSVLink: false,
  csvData: null,
  getCsvFileName: () => {},
};

export default HeaderDrawer;
