import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import EntityFormTemplate from './EntityFormTemplate';
import { renderSelectValues } from '../../../utils/utils';
import { postAgencyAction } from '../../../business/actions/agencyAction';

const AgencyForm = () => {
  const { companies } = useSelector((state) => state.companies);
  const { t } = useTranslation();

  const companyField = [
    {
      label: t('settings.agency.company'),
      name: 'company',
      type: 'select',
      selectValues: renderSelectValues(companies),
      placeholder: t('settings.agency.placeholderCompany'),
      rules: [
        {
          required: true,
          message: t('settings.company.companyHelp'),
        },
      ],
    },
  ];

  return (
    (
      <EntityFormTemplate
        type="agency"
        companyField={companyField}
        postAction={postAgencyAction}
      />
    )
  );
};

export default AgencyForm;
