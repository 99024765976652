import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { formatDate, getQualificationValue } from '../../../utils/utils';
import { Context } from '../../../utils/context/Intlcontext';
import HeaderDrawer from '../drawer/HeaderDrawer';

const MobilesDetailsDrawerHeader = ({ currentMobile, tableData }) => {
  const { t } = useTranslation();
  const { initialLang } = useContext(Context);

  const drawerTitle = `${t(
    'dashboard.mobileDetails.title',
    {
      numberMobile: currentMobile?.telephoneNumber,
      dateCSV: formatDate(currentMobile?.selectedMonth, t('format.monthYear')),
    },
  )} / Android id: ${currentMobile?.androidId}`;

  const getCsvFileName = () => {
    const dateCSV = `${formatDate(currentMobile?.selectedMonth, t('format.monthYear'))}`;
    return t('dashboard.mobileDetails.title', { numberMobile: currentMobile?.telephoneNumber, dateCSV });
  };

  const csvData = useMemo(() => [
    [
      t('dashboard.mobileDetails.day'),
      t('dashboard.mobileDetails.place'),
      t('dashboard.mobileDetails.decision'),
      t('dashboard.mobileDetails.qualification'),
      t('dashboard.mobileDetails.power'),
      t('dashboard.mobileDetails.frequency'),
    ],
    ...tableData.map((sound) => {
      const qualificationValue = getQualificationValue(sound.qualification);
      return ([
        formatDate(sound.timeStamp, t('format.dayTime'), initialLang),
        sound.place,
        sound.decision === 1 ? t('dashboard.mobileDetails.leak') : t('dashboard.mobileDetails.noLeak'),
        t(`records.${qualificationValue}`),
        Math.round(sound.power),
        sound.frequency,
      ]);
    }),
  ], [tableData]);

  return (
    <HeaderDrawer
      title={drawerTitle}
      showCSVLink
      csvData={csvData}
      getCsvFileName={getCsvFileName}
    />
  );
};

MobilesDetailsDrawerHeader.propTypes = {
  currentMobile: PropTypes.shape({
    telephoneNumber: PropTypes.string.isRequired,
    selectedMonth: PropTypes.number.isRequired,
    androidId: PropTypes.string.isRequired,
  }),
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentRecord: PropTypes.shape({}),
};

MobilesDetailsDrawerHeader.defaultProps = {
  currentMobile: null,
  currentRecord: null,
};

export default MobilesDetailsDrawerHeader;
