import axios from 'axios';

export const apiServ = process.env.REACT_APP_API_URL;
export const apiVersion = process.env.REACT_APP_API_VERSION;
export const axiosInstance = axios.create();

const constructApiUrl = (url) => `${apiServ}/${apiVersion}/${url}`;

const headers = (token) => {
  const header = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return header;
};

const get = async (apiUrl, token) => {
  const urlAPI = constructApiUrl(apiUrl);
  const response = await axiosInstance.get(urlAPI, headers(token));
  return response;
};

const create = async (url, data, origin) => {
  const urlAPI = constructApiUrl(url);
  const token = origin === 'canal' ? data[1] : data.token;
  const dataToSend = origin === 'canal' ? [data[0]] : data;

  const response = await axiosInstance.post(urlAPI, dataToSend, headers(token));
  return response.data;
};

const update = async (url, data, origin) => {
  const { token } = data;
  const urlAPI = constructApiUrl(url);
  const dataToSend = origin === 'mobileStatus' ? data.status : data;
  const response = await axiosInstance.patch(urlAPI, dataToSend, headers(token));
  return response;
};

const auth = async (url, user) => {
  const urlAPI = constructApiUrl(url);
  const response = await axiosInstance.post(urlAPI, user);
  return response.headers;
};

export default {
  get,
  create,
  update,
  auth,
};
