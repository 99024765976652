import {
  CALIBRATEDPHONES, PHONENUMBER, PHONENUMBERSTATUS, PHONESNUMBER,
} from '../actions/mobileAction';

const INITIAL_STATE = {
  mobiles: null,
  isPhoneNumberExist: null,
  loading: false,
  message: null,
  calibrationStatus: { calibrated: 0, inProcess: 0 },
};

const mobileReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PHONESNUMBER.FETCH.REQUEST:
      return { ...state, loading: true };
    case PHONESNUMBER.FETCH.SUCCESS:
      return {
        ...state, mobiles: payload, loading: false,
      };
    case PHONESNUMBER.FETCH.ERROR:
      return { ...state, mobiles: payload, loading: false };
    case PHONESNUMBER.FETCH.FAILURE:
      return { ...state, loading: false };
    case PHONENUMBER.FETCH.REQUEST:
      return { ...state, loading: true };
    case PHONENUMBER.FETCH.SUCCESS:
      return { ...state, isPhoneNumberExist: payload, loading: false };
    case PHONENUMBER.FETCH.ERROR:
      return { ...state, isPhoneNumberExist: payload, loading: false };
    case PHONENUMBER.FETCH.FAILURE:
      return { ...state, loading: false };
    case CALIBRATEDPHONES.FETCH.REQUEST:
      return { ...state, loading: true };
    case CALIBRATEDPHONES.FETCH.SUCCESS:
      return { ...state, calibrationStatus: payload, loading: false };
    case CALIBRATEDPHONES.FETCH.ERROR:
      return { ...state, calibrationStatus: payload, loading: false };
    case PHONENUMBER.POST.REQUEST:
      return { ...state, loading: true };
    case PHONENUMBER.POST.SUCCESS:
      return {
        ...state, list: payload, isPhoneNumberExist: null, loading: false,
      };
    case PHONENUMBER.POST.FAILURE:
      return { ...state, message: payload, loading: false };
    case PHONENUMBER.PATCH.REQUEST:
      return { ...state, loading: true };
    case PHONENUMBER.PATCH.SUCCESS:
      return { ...state, message: payload, loading: false };
    case PHONENUMBER.PATCH.FAILURE:
      return { ...state, message: payload, loading: false };
    case PHONENUMBERSTATUS.PATCH.REQUEST:
      return { ...state, loading: true };
    case PHONENUMBERSTATUS.PATCH.SUCCESS:
      return { ...state, message: payload, loading: false };
    case PHONENUMBERSTATUS.PATCH.FAILURE:
      return { ...state, message: payload, loading: false };
    default:
      return state;
  }
};

export default mobileReducer;
