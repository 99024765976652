import { Layout } from 'antd';
import React, {
  useState, useCallback,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import RecordsList from '../shared/recordElements/RecordsList';
import RecordMobileInfosModal from '../shared/recordElements/modal/RecordMobileInfosModal';
import { getAnalysisAction } from '../../business/actions/analysisAction';
import UpdateData from '../shared/UpdateData';
import MobilesDetailsDrawer from '../shared/mobilesDetails/MobilesDetailsDrawer';

const Sounds = () => {
  const { analysis } = useSelector((state) => state.analysis);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentMobile, setCurrentMobile] = useState(null);
  const [typeChart, setTypeChart] = useState(null);

  useEffect(() => {
    if (currentRecord && isOpenDrawer) {
      setCurrentMobile({
        telephoneNumber: currentRecord.telephoneNumber,
        selectedMonth: currentRecord.timestamp,
        androidId: currentRecord.androidId,
      });
    }
  }, [currentRecord, isOpenDrawer]);

  const handleShowChartModal = useCallback((record, type) => {
    setCurrentRecord(record);
    setTypeChart(type);
    setIsOpenModal(true);
  }, []);

  const handleShowDrawer = useCallback((record) => {
    setCurrentRecord(record);
    setIsOpenDrawer(true);
  }, []);

  return (
    <Layout className="sounds">
      <RecordMobileInfosModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        currentRecord={currentRecord}
        typeChart={typeChart}
      />
      <MobilesDetailsDrawer
        currentMobile={currentMobile}
        setCurrentMobile={setCurrentMobile}
        setIsOpenDrawer={setIsOpenDrawer}
        currentRecord={currentRecord}
        origin="records"
      />
      <UpdateData stateToUpdate={getAnalysisAction} timeToRefresh={18000} />
      <RecordsList
        recordsList={analysis}
        handleShowChartModal={handleShowChartModal}
        handleShowDrawer={handleShowDrawer}
        typeChart="densityPower"
      />
    </Layout>
  );
};

export default Sounds;
