import { RECORDS } from '../actions/recordsAction';

const INITIAL_STATE = {
  recordsList: null, loading: false, lastRecord: null,
};

const recordsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RECORDS.FETCH.REQUEST:
      return { ...state, loading: true };
    case RECORDS.FETCH.SUCCESS:
      return {
        ...state, recordsList: payload.dataRecords, lastRecord: payload.lastRecord, loading: false,
      };
    case RECORDS.FETCH.FAILURE:
      return { ...state, loading: true };
    case RECORDS.FETCH.ERROR:
      return { ...state, loading: true, error: payload };
    default:
      return state;
  }
};

export default recordsReducer;
