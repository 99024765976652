import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';

export const CANALWIDTH = createRequestTypes('CANALWIDTH', [FETCH, POST]);

export const getCanalWidthsAction = {
  request: (payload) => action(CANALWIDTH.FETCH.REQUEST, payload),
  success: (data) => action(CANALWIDTH.FETCH.SUCCESS, data),
  error: (message) => action(CANALWIDTH.FETCH.ERROR, message),
  failure: (error) => action(CANALWIDTH.FETCH.FAILURE, error),
};

export const postCanalWidthAction = {
  request: (payload) => action(CANALWIDTH.POST.REQUEST, payload),
  success: (data) => action(CANALWIDTH.POST.SUCCESS, data),
  error: (message) => action(CANALWIDTH.POST.ERROR, message),
  failure: (error) => action(CANALWIDTH.POST.FAILURE, error),
};
