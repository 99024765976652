import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AgencyDetailList from './AgencyDetailList';
import MobilesDetailsDrawer from '../../shared/mobilesDetails/MobilesDetailsDrawer';
import SkeletonList from '../../shared/skeletons/SkeletonList';

const AgenciesDetails = () => {
  const { details, loading: detailsLoading } = useSelector((state) => state.analysis);
  const [currentMobile, setCurrentMobile] = useState(null);
  const isDetailsDataLoaded = details && !detailsLoading;

  const renderAgenciesDetailsList = () => {
    if (isDetailsDataLoaded) {
      return (
        <div className="agencies-details">
          {details?.map((agencyDetail) => (
            <AgencyDetailList
              key={agencyDetail.name}
              agencyDetail={agencyDetail}
              setCurrentMobile={setCurrentMobile}
            />
          ))}
        </div>
      );
    }
    return <SkeletonList />;
  };

  return (
    <>
      <MobilesDetailsDrawer
        currentMobile={currentMobile}
        setCurrentMobile={setCurrentMobile}
      />
      {renderAgenciesDetailsList()}
    </>
  );
};

export default AgenciesDetails;
