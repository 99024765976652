import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import DashboardList from '../../shared/SharedStyle';
import DoughnutChart from '../../shared/charts/DoughnutChart';

const AgenciesChart = ({ chartData, name }) => {
  const { t } = useTranslation();
  const labelsChart = [t('monitoring.agenciesChart.leak'), t('monitoring.agenciesChart.noLeak')];
  return (
    <DashboardList width={120}>
      <h3 className="title-dark-default">{name}</h3>
      <DoughnutChart dataChart={chartData} labelsData={labelsChart} color={3} />
    </DashboardList>
  );
};

AgenciesChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.number).isRequired,
  name: PropTypes.string.isRequired,
};

export default AgenciesChart;
