import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDrawer from '../../shared/drawer/CustomDrawer';
import OrderSteps from './orderSteps';
import OrderStatus from './orderStatus';
import HeaderDrawer from '../../shared/drawer/HeaderDrawer';

const OrdersDrawer = ({
  isOpenDrawer,
  setIsOpenDrawer,
  typeDrawer,
  currentStatus,
  currentOrder,
  setCurrentStatus,
  getLatestOrderStatus,
}) => {
  const { t } = useTranslation();

  const renderDrawerType = typeDrawer === 'form' ? <OrderSteps setOpenDrawer={setIsOpenDrawer} getLatestOrderStatus={getLatestOrderStatus} />
    : (
      <OrderStatus
        setIsOpenDrawer={setIsOpenDrawer}
        currentStatus={currentStatus}
        currentOrder={currentOrder}
        setCurrentStatus={setCurrentStatus}
      />
    );
  const typeTitle = typeDrawer === 'form' ? t('orders.form.titleForm') : t('orders.form.titleStatus');

  const title = <HeaderDrawer title={typeTitle} />;

  return (
    <CustomDrawer
      className="orders-drawer"
      title={title}
      width="50%"
      isOpenDrawer={isOpenDrawer}
      setIsOpenDrawer={setIsOpenDrawer}
    >
      {renderDrawerType}
    </CustomDrawer>
  );
};

OrdersDrawer.propTypes = {
  isOpenDrawer: PropTypes.bool.isRequired,
  setIsOpenDrawer: PropTypes.func.isRequired,
  typeDrawer: PropTypes.string.isRequired,
  currentOrder: PropTypes.string.isRequired,
  currentStatus: PropTypes.shape({}),
  setCurrentStatus: PropTypes.func.isRequired,
  getLatestOrderStatus: PropTypes.func.isRequired,
};

OrdersDrawer.defaultProps = {
  currentStatus: null,
};

export default OrdersDrawer;
