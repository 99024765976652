import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomDrawer from '../drawer/CustomDrawer';
import MapLeak from '../map';
import MobileDetailsList from './MobileDetailsList';
import MobilesDetailsDrawerHeader from './MobileDetailsDrawerHeader';

const MobilesDetailsDrawer = ({
  currentMobile,
  setCurrentMobile,
  currentRecord,
  origin,
}) => {
  const [tableData, setTableData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [currentHoverRowList, setCurrentHoverRowList] = useState(null);
  const [isOpenDrawer, setIsOpenDrawer] = useState(!!currentMobile);
  const showMap = mapData.length > 0;

  const title = <MobilesDetailsDrawerHeader currentMobile={currentMobile} tableData={tableData} />;

  useEffect(() => {
    setIsOpenDrawer(!!currentMobile);
  }, [currentMobile]);

  return (
    <CustomDrawer
      className="mobile-details-drawer"
      title={title}
      width="85%"
      isOpenDrawer={isOpenDrawer}
      setIsOpenDrawer={setIsOpenDrawer}
      setCurrentState={setCurrentMobile}
    >
      <MobileDetailsList
        tableData={tableData}
        setTableData={setTableData}
        currentMobile={currentMobile}
        setMapData={setMapData}
        setCurrentHoverRowList={setCurrentHoverRowList}
        currentRecord={currentRecord}
        origin={origin}
      />
      {showMap && (
      <MapLeak
        data={mapData}
        currentHoverRowList={currentHoverRowList}
      />
      )}
    </CustomDrawer>
  );
};

MobilesDetailsDrawer.propTypes = {
  currentMobile: PropTypes.shape({
    telephoneNumber: PropTypes.string.isRequired,
    selectedMonth: PropTypes.number.isRequired,
    androidId: PropTypes.string,
  }),
  setCurrentMobile: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({}),
  origin: PropTypes.string,
};

MobilesDetailsDrawer.defaultProps = {
  currentMobile: null,
  currentRecord: null,
  origin: '',
};

export default MobilesDetailsDrawer;
