import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';

export const CANALTYPE = createRequestTypes('CANALTYPE', [FETCH, POST]);

export const getCanalTypesAction = {
  request: (payload) => action(CANALTYPE.FETCH.REQUEST, payload),
  success: (data) => action(CANALTYPE.FETCH.SUCCESS, data),
  error: (message) => action(CANALTYPE.FETCH.ERROR, message),
  failure: (error) => action(CANALTYPE.FETCH.FAILURE, error),
};

export const postCanalTypeAction = {
  request: (payload) => action(CANALTYPE.POST.REQUEST, payload),
  success: (data) => action(CANALTYPE.POST.SUCCESS, data),
  error: (message) => action(CANALTYPE.POST.ERROR, message),
  failure: (error) => action(CANALTYPE.POST.FAILURE, error),
};
