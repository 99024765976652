import {
  DatePicker, Form, Input,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  children,
  className,
  style,
  colSpan,
  rowSpan,
  type,
  max,
  min,
  onBeforeInput,
}) => {
  const { t } = useTranslation();

  const getInputNode = (inputNodeType) => {
    switch (inputNodeType) {
      case 'date':
        return <DatePicker />;
      default:
        return (
          <Input
            type={type}
            onBeforeInput={onBeforeInput}
            max={max}
            min={min}
          />
        );
    }
  };

  const inputNode = getInputNode(inputType);

  return (
    <td
      className={className}
      style={style}
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      {editing ? (
        <Form.Item
          name={dataIndex}
          className="editable-cell"
          rules={[
            {
              required: false,
              message: t('shared.formTemplate.requiredField'),
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  inputType: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  colSpan: PropTypes.number,
  rowSpan: PropTypes.number,
  type: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onBeforeInput: PropTypes.func,
};

EditableCell.defaultProps = {
  editing: false,
  dataIndex: '',
  inputType: 'text',
  children: null,
  className: '',
  style: {},
  colSpan: null,
  rowSpan: null,
  max: null,
  min: null,
  onBeforeInput: null,
  type: 'text',
};

export default EditableCell;
