import { put, takeEvery, call } from 'redux-saga/effects';
import { AUTH, postAuthAction } from '../actions/authAction';
import postAuthService from '../services/authService';
import { handleSagaSuccessNotification, handleSagaErrorNotification } from '../utils/responseHandler';

export function* postAuth({ payload }) {
  try {
    const token = yield call(postAuthService, payload);
    if (token) {
      yield put(postAuthAction.success(token));
      handleSagaSuccessNotification('shared.notification.auth.success');
    }
  } catch (err) {
    yield put(postAuthAction.failure(err));
    handleSagaErrorNotification(err, 'auth');
  }
}

export function* postAuthSaga() {
  yield takeEvery(AUTH.POST.REQUEST, postAuth);
}
