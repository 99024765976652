import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import FormTemplate from './FormTemplate';
import { getTimestampsPeriod, handleStepChange, regexNumber } from '../../../utils/utils';
import { postOrderAction } from '../../../business/actions/orderAction';
import { AuthContext } from '../../../utils/context/AuthContext';

const OrderForm = ({
  setIsFormValid, currentCompanySelected, setCurrentStep,
}) => {
  const { orders, message: msgErrorOrder } = useSelector((state) => state.orders);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const { token, role } = useContext(AuthContext);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = currentCompanySelected.value;

  const handleValuesChange = async () => {
    const values = form.getFieldsValue();
    const isValidForm = values.quoteNumber && values.totalAmount;
    setIsFormValid(isValidForm);
  };

  useEffect(() => {
    if (!msgErrorOrder && isSubmitForm) {
      form.resetFields();
      handleStepChange(setCurrentStep, 'next');
      setIsSubmitForm(false);
    }
  }, [isSubmitForm, msgErrorOrder]);

  const onFinish = (finalData) => {
    const resultPromise = [];
    const {
      quoteNumber,
      totalAmount,
      orderNumber,
      invoiceNumber,
      period,
      licenceLimit,
      duration,
      name,
    } = finalData;

    const periodDate = getTimestampsPeriod(period);
    const { startTimestamp, expiryTimestamp } = periodDate;
    if (quoteNumber && totalAmount) {
      resultPromise.push(dispatch(postOrderAction.request({
        companyId: currentCompanyId,
        name,
        orderNumber: orderNumber || '',
        invoiceNumber: invoiceNumber || '',
        quoteNumber,
        totalAmount,
        startTimestamp: startTimestamp || 0,
        expiryTimestamp: expiryTimestamp || 0,
        renewalDuration: duration || 0,
        licenceLimit: licenceLimit || 0,
        token,
        role,
      })));
      setIsSubmitForm(true);
    } else {
      return Promise.reject(new Error('error'));
    }
    return Promise.allSettled(resultPromise);
  };

  const checkElementIsExisting = ({ value, element }) => {
    const isQuoteNumberExist = orders?.some((order) => order[element] === value);
    return isQuoteNumberExist ? Promise.reject(new Error(t('orders.form.numberAlreadyAssigned'))) : Promise.resolve();
  };

  const variables = [
    {
      label: t('orders.form.orderName'),
      name: 'name',
      placeholder: t('orders.form.placeholderName'),
    },
    {
      label: t('orders.quoteNumber'),
      name: 'quoteNumber',
      rules: [
        { required: true, message: t('orders.form.quoteNumberHelp') },
        () => ({
          validator(_, value) {
            return checkElementIsExisting({ value, element: 'quoteNumber' });
          },
        }),
      ],
      placeholder: t('orders.form.quoteNumberPlaceholder'),
    },
    {
      label: t('orders.totalAmount'),
      name: 'totalAmount',
      placeholder: t('orders.form.totalAmountPlaceholder'),
      rules: [
        { required: true, message: t('orders.form.totalAmountHelp') },
        { pattern: regexNumber, message: t('orders.form.numberValidation') },
      ],
    },
    {
      label: t('orders.licenceLimit'),
      name: 'licenceLimit',
      type: 'number',
      rules: [
        { pattern: regexNumber, message: t('orders.form.numberValidation') },
      ],
      placeholder: t('orders.form.licenceLimitPlaceholder'),
    },
    {
      label: t('orders.orderNumber'),
      name: 'orderNumber',
      placeholder: t('orders.form.orderNumberPlaceholder'),
      rules: [
        () => ({
          validator(_, value) {
            return checkElementIsExisting({ value, element: 'orderNumber' });
          },
        }),
      ],
    },
    {
      label: t('orders.invoiceNumber'),
      name: 'invoiceNumber',
      placeholder: t('orders.form.invoiceNumberPlaceholder'),
      rules: [
        () => ({
          validator(_, value) {
            return checkElementIsExisting({ value, element: 'invoiceNumber' });
          },
        }),
      ],
    },
    {
      label: t('orders.duration'),
      name: 'duration',
      rules: [
        { pattern: regexNumber, message: t('orders.form.numberValidation') },
      ],
      placeholder: t('orders.form.durationPlaceholder'),
    },
    {
      label: 'Période',
      name: 'period',
      type: 'rangeDate',
      placeholder: t('orders.form.periodPlaceholder'),
    },
  ];

  return (
    <FormTemplate
      nameForm="order-form"
      className="settings-form order-form"
      useForm={form}
      variables={variables}
      buttonValidText={t('shared.formTemplate.submit')}
      onValuesChange={handleValuesChange}
      onFinishApiCall={onFinish}
    />
  );
};

OrderForm.propTypes = {
  currentCompanySelected: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  orderDetailsData: PropTypes.shape({}),
  setIsFormValid: PropTypes.func,
  setCurrentStep: PropTypes.func,
};

OrderForm.defaultProps = {
  currentCompanySelected: null,
  orderDetailsData: {},
  setIsFormValid: () => {},
  setCurrentStep: () => {},
};

export default OrderForm;
