import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomModal from '../../modal';
import RecordCharts from '../charts/RecordCharts';
import { formatDate } from '../../../../utils/utils';
import SynthesisCards from '../synthesisCards';
import MobileInfosTitle from '../MobileInfosTitle';

const RecordMobileInfosModal = ({
  isOpenModal,
  setIsOpenModal,
  typeChart,
  currentRecord,
  currentNumberMobile,
}) => {
  const { t } = useTranslation();
  const isRecordExist = currentRecord && Object.keys(currentRecord).length > 0;
  const numberMobile = currentRecord?.telephoneNumber || currentNumberMobile;
  const date = formatDate(currentRecord?.timeStamp, t('format.dayMonthYearTime'));
  const title = `${numberMobile} - ${date}`;
  const mobileInfosTitle = <MobileInfosTitle title={title} />;

  return (
    <CustomModal
      width={isRecordExist ? 800 : 360}
      title={mobileInfosTitle}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      isRecordExist={isRecordExist}
    >
      <RecordCharts
        currentRecord={currentRecord}
        currentNumberMobile={currentNumberMobile}
        typeChart={typeChart}
        isModal
      />
      <SynthesisCards currentRecord={currentRecord} />
    </CustomModal>
  );
};

RecordMobileInfosModal.propTypes = {
  currentNumberMobile: PropTypes.string,
  isOpenModal: PropTypes.bool.isRequired,
  setIsOpenModal: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({
    densityPower: PropTypes.arrayOf(PropTypes.number),
    timeStamp: PropTypes.number,
    telephoneNumber: PropTypes.string,
  }),
  typeChart: PropTypes.string,
};

RecordMobileInfosModal.defaultProps = {
  currentNumberMobile: null,
  currentRecord: null,
  typeChart: null,
};

export default RecordMobileInfosModal;
