import React, {
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DashboardList from '../SharedStyle';
import TableTemplate from '../TableTemplate';
import { formatDate, getQualificationValue } from '../../../utils/utils';
import { Context } from '../../../utils/context/Intlcontext';

const MobileDetailsList = ({
  currentMobile,
  setMapData,
  setCurrentHoverRowList,
  tableData,
  setTableData,
  currentRecord,
  origin,
}) => {
  const { analysis } = useSelector((state) => state.analysis);
  const { initialLang } = useContext(Context);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    if (analysis && currentMobile) {
      const { telephoneNumber, selectedMonth } = currentMobile;
      const selectedMonthYearFormat = formatDate(selectedMonth, t('format.monthYear'));
      const filteredSounds = analysis.filter((record) => {
        const recordMonthYearFormat = formatDate(record.timeStamp, t('format.monthYear'));
        const mobileMatch = record.telephoneNumber === telephoneNumber;
        const timestampMatch = recordMonthYearFormat === selectedMonthYearFormat;
        return mobileMatch && timestampMatch;
      });
      const sortedSound = filteredSounds.sort((a, b) => b.timeStamp - a.timeStamp);

      sortedSound?.forEach((sound) => {
        const formattedDate = formatDate(sound.timeStamp, t('format.dayTime'), initialLang);
        data.push({
          key: sound.blockChainHash,
          date: formattedDate,
          place: sound.commune,
          decision: sound.decision,
          qualification: sound.qualification,
          power: sound.leakPower,
          frequency: sound.leakFrequency,
          latitude: sound.latitude,
          longitude: sound.longitude,
          timestamp: sound.timeStamp,
        });
      });
      setTableData(data);
      setMapData(data);
    }
  };

  useEffect(() => {
    renderData();
  }, [analysis, currentMobile]);

  const columns = [
    {
      title: t('dashboard.mobileDetails.day'),
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 50,
    },
    {
      title: t('dashboard.mobileDetails.place'),
      dataIndex: 'place',
      key: 'place',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 45,
    },
    {
      title: t('dashboard.mobileDetails.decision'),
      dataIndex: 'decision',
      key: 'decision',
      align: 'center',
      render: (_, sound) => {
        const { decision } = sound;
        return (
          <span className={decision === 1 ? 'red' : ''}>
            {decision === 1 ? t('dashboard.mobileDetails.leak') : t('dashboard.mobileDetails.noLeak')}
          </span>
        );
      },
      responsive: ['md', 'lg'],
      width: 40,
    },
    {
      title: t('dashboard.mobileDetails.qualification'),
      dataIndex: 'qualification',
      key: 'qualification',
      align: 'center',
      render: (_, sound) => {
        const { qualification } = sound;
        const qualificationValue = getQualificationValue(qualification);
        return <span>{t(`records.${qualificationValue}`)}</span>;
      },
      width: 55,
    },
    {
      title: t('dashboard.mobileDetails.power'),
      dataIndex: 'power',
      key: 'power',
      align: 'center',
      render: (_, sound) => Math.round(sound.power),
      responsive: ['md', 'lg'],
      width: 45,
    },
    {
      title: t('dashboard.mobileDetails.frequency'),
      dataIndex: 'frequency',
      key: 'frequency',
      align: 'center',
      responsive: ['md', 'lg'],
      width: 45,
    },
  ];

  const height = window.innerHeight - 110;

  return (
    <DashboardList padding="10px" width="46%" margin="20px" maxHeight={`${height}px`}>
      <TableTemplate
        typeList="fullHeight"
        origin="drawer"
        columns={columns}
        data={tableData}
        setCurrentHoverRowList={setCurrentHoverRowList}
        rowClassName={(record) => (origin === 'records' && record.key === currentRecord?.blockChainHash ? 'bg-white-light' : '')}
      />
    </DashboardList>
  );
};

MobileDetailsList.propTypes = {
  currentMobile: PropTypes.shape({
    telephoneNumber: PropTypes.string,
    selectedMonth: PropTypes.number,
  }),
  setMapData: PropTypes.func.isRequired,
  setCurrentHoverRowList: PropTypes.func.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setTableData: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({
    blockChainHash: PropTypes.string,
  }),
  origin: PropTypes.string,
};

MobileDetailsList.defaultProps = {
  currentMobile: {
    telephoneNumber: '',
    selectedMonth: '',
  },
  currentRecord: {
    blockChainHash: null,
  },
  origin: '',
};

export default MobileDetailsList;
