import {
  ORDER, ORDERDETAILS, ORDERSTATUS, LASTORDER,
} from '../actions/orderAction';

const INITIAL_STATE = {
  orders: null, message: null, loading: false, lastOrder: null,
};

const orderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER.FETCH.REQUEST:
      return { ...state, loading: true };
    case ORDER.FETCH.SUCCESS:
      return { ...state, orders: payload, loading: false };
    case ORDER.FETCH.FAILURE:
      return { ...state, loading: false };
    case LASTORDER.FETCH.REQUEST:
      return { ...state, loading: true };
    case LASTORDER.FETCH.SUCCESS:
      return { ...state, lastOrder: payload, loading: false };
    case LASTORDER.FETCH.FAILURE:
      return { ...state, loading: false };
    case ORDER.POST.REQUEST:
      return { ...state, loading: true };
    case ORDER.POST.SUCCESS:
      return { ...state, message: payload, loading: false };
    case ORDER.POST.FAILURE:
      return { ...state, message: payload, loading: false };
    case ORDERDETAILS.PATCH.REQUEST:
      return { ...state, loading: true };
    case ORDERDETAILS.PATCH.SUCCESS:
      return { ...state, message: payload, loading: false };
    case ORDERDETAILS.PATCH.FAILURE:
      return { ...state, message: payload, loading: false };
    case ORDERSTATUS.PATCH.REQUEST:
      return { ...state, loading: true };
    case ORDERSTATUS.PATCH.SUCCESS:
      return { ...state, message: payload, loading: false };
    case ORDERSTATUS.PATCH.FAILURE:
      return { ...state, message: payload, loading: false };
    default:
      return state;
  }
};

export default orderReducer;
