import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { postCompanyAction } from '../../../business/actions/companyAction';
import { renderSelectValues } from '../../../utils/utils';
import EntityFormTemplate from './EntityFormTemplate';

const CompanyForm = () => {
  const { distributors } = useSelector((state) => state.distributors);
  const { t } = useTranslation();

  const distributorField = [
    {
      label: t('settings.distributor.name'),
      name: 'distributor',
      type: 'select',
      selectValues: renderSelectValues(distributors),
      placeholder: t('settings.distributor.placeholderName'),
      rules: [
        {
          required: true,
          message: t('settings.distributor.nameHelp'),
        },
      ],
    },
  ];
  return (
    <EntityFormTemplate
      type="company"
      postAction={postCompanyAction}
      distributorField={distributorField}
    />
  );
};

export default CompanyForm;
