import React from 'react';
import PropTypes from 'prop-types';
import RecordCharts from './RecordCharts';

const SynthesisCharts = ({ currentRecord, type }) => {
  const {
    densityPower,
    telephoneNumber,
    temporalContinuityVector,
  } = currentRecord || {};

  const chartsSoundsData = {
    temporalContinuityVector,
    densityPower,
    telephoneNumber,
  };
  const chartsAnalysisData = {
    densityPower,
    telephoneNumber,
  };

  return (
    <div className="synthesis-charts">
      <RecordCharts
        currentRecord={type === 'analysis' ? chartsAnalysisData : chartsSoundsData}
        typeChart="all"
        height={200}
        width={280}
      />
    </div>
  );
};

SynthesisCharts.propTypes = {
  type: PropTypes.string.isRequired,
  currentRecord: PropTypes.shape({
    densityPower: PropTypes.arrayOf(PropTypes.number),
    telephoneNumber: PropTypes.string.isRequired,
  }),
};

SynthesisCharts.defaultProps = {
  currentRecord: null,
};

export default SynthesisCharts;
