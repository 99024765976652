import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleOutlined, FrownOutlined, LoadingOutlined } from '@ant-design/icons';
import { CardStyled } from '../../../shared/SharedStyle';
import { getTimestampByStatus, setColorByStatus } from './config';
import { formatDate } from '../../../../utils/utils';
import PopConfirmStatusUpdate from './PopConfirmStatusUpdate';

const TimelineStatus = ({
  orders, currentOrder, currentStatus, setCurrentStatus,
}) => {
  const { t } = useTranslation();
  const currentTimestampStatus = getTimestampByStatus(orders, currentOrder);
  const orderIsInProgress = currentTimestampStatus?.some((elt) => elt.status === 30);
  const lastCurrentStatus = currentStatus.status;

  useEffect(() => {
    setCurrentStatus(currentTimestampStatus?.[0]);
  }, [orders]);

  const renderLabel = (id) => {
    const isLabelActive = currentTimestampStatus?.some((elt) => elt.status === id);
    const renderTimestamp = currentTimestampStatus?.filter((elt) => elt.status === id);

    if (isLabelActive) {
      return <span className="text-bold">{formatDate(renderTimestamp[0].timestamp)}</span>;
    }
    return null;
  };
  const renderChildren = (id, title) => {
    const isPopInactive = currentTimestampStatus?.some((elt) => elt.status === id);
    const orderWasBlocked = currentTimestampStatus?.some((elt) => elt.status === 33);
    const orderWasAbandoned = currentTimestampStatus?.some((elt) => elt.status === 100);
    const basicChildren = <p className="lightgrey">{title}</p>;

    if (lastCurrentStatus === 100 || orderWasAbandoned) {
      return basicChildren;
    }
    if (lastCurrentStatus !== 33 && orderWasBlocked && id === 33) {
      return basicChildren;
    }
    if (isPopInactive) {
      return (
        <p className={id === 32 || lastCurrentStatus === 33 ? 'red' : setColorByStatus(id, lastCurrentStatus)}>{title}</p>
      );
    }
    return (
      <PopConfirmStatusUpdate item={id} color="lightgrey" label={title} currentOrder={currentOrder} />
    );
  };

  const renderDot = () => {
    if (lastCurrentStatus === 33) {
      return <FrownOutlined />;
    }
    if (lastCurrentStatus >= 30 && lastCurrentStatus < 32) {
      return <LoadingOutlined />;
    }
    if (lastCurrentStatus < 32 && orderIsInProgress) {
      return <LoadingOutlined />;
    }
    if (lastCurrentStatus === 32) {
      return <CloseCircleOutlined />;
    }
    return null;
  };

  return (
    <CardStyled width={450}>
      <Timeline
        mode="alternate"
        items={[
          {
            children: (
              <p className={setColorByStatus(10, lastCurrentStatus)}>{t('orders.quoteSent')}</p>
            ),
            label: (
              renderLabel(10)
            ),
            color: setColorByStatus(10, lastCurrentStatus),
          },
          {
            children: (
              renderChildren(11, t('orders.quoteSigned'))
            ),
            label: (
              renderLabel(11)
            ),
            color: lastCurrentStatus > 11 ? setColorByStatus(11, lastCurrentStatus) : 'lightgrey',
          },
          {
            children: (
              renderChildren(20, t('orders.orderSent'))
            ),
            label: (
              renderLabel(20)
            ),
            color: setColorByStatus(20, lastCurrentStatus),
          },
          {
            children: (
              renderChildren(21, t('orders.orderValidated'))
            ),
            label: (
              renderLabel(21)
            ),
            color: setColorByStatus(21, lastCurrentStatus),
          },
          {
            children: (
              renderChildren(22, t('orders.invoiceSent'))
            ),
            label: (
              renderLabel(22)
            ),
            color: setColorByStatus(22, lastCurrentStatus),
          },
          {
            children: (
              renderChildren(30, t('orders.orderInProgress'))
            ),
            label: (
              renderLabel(30)
            ),
            color: setColorByStatus(30, lastCurrentStatus),
            dot: (
              renderDot()
            ),
          },
          {
            children: (
              renderChildren(31, t('orders.orderAboutToBeFinished'))
            ),
            label: (
              renderLabel(31)
            ),
            color: lastCurrentStatus === 31 ? 'orange' : 'lightgrey',
          },
          {
            children: (
              renderChildren(32, t('orders.orderFinished'))
            ),
            label: (
              renderLabel(32)
            ),
            color: lastCurrentStatus === 32 ? 'red' : 'lightgrey',
          },
          {
            children: (
              renderChildren(33, t('orders.orderBlocked'))
            ),
            label: (
              renderLabel(33)
            ),
            color: setColorByStatus(33, lastCurrentStatus),
          },
          {
            children: (
              renderChildren(100, t('orders.orderAbandoned'))
            ),
            label: (
              renderLabel(100)
            ),
            color: setColorByStatus(100, lastCurrentStatus),
          },
        ]}
      />
    </CardStyled>
  );
};

TimelineStatus.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentOrder: PropTypes.string.isRequired,
  currentStatus: PropTypes.shape({
    timestamp: PropTypes.number,
    status: PropTypes.number,
  }).isRequired,
  setCurrentStatus: PropTypes.func.isRequired,
};

export default TimelineStatus;
