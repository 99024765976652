import api from '../utils/query';

const baseUrl = 'analysis_sound';

export const getAnalysisService = (token) => api.get(baseUrl, token);

export const getAnalysisByUserService = (token) => {
  const apiUrl = `${baseUrl}/user`;
  return api.get(apiUrl, token);
};
