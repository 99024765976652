import { call, put, takeEvery } from 'redux-saga/effects';
import {
  RECORDS, getRecordsAction,
} from '../actions/recordsAction';
import { getRecordsService, getRecordsByUserService } from '../services/recordsService';
import { handleSagaErrorNotification } from '../utils/responseHandler';
import { isProdEnv } from '../../utils/utils';

export const selectGetRecordsService = (role) => (
  role !== 1 ? getRecordsByUserService : getRecordsService
);

export function* getRecords({ payload }) {
  const { token, role } = payload;
  const service = selectGetRecordsService(role);
  try {
    const records = yield call(service, token);
    if (records) {
      const { data } = records;
      data.sort((a, b) => b.timestamp - a.timestamp);
      const lastRecord = data[0];
      yield put(getRecordsAction.success({ dataRecords: data, lastRecord }));
    } else {
      yield put(getRecordsAction.error("pas d'enregistrements"));
    }
  } catch (err) {
    if (!isProdEnv) {
      handleSagaErrorNotification(err, 'records');
    }
    yield put(getRecordsAction.failure(err));
  }
}

export function* getRecordsSaga() {
  yield takeEvery(RECORDS.FETCH.REQUEST, getRecords);
}
