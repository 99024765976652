import { USERS } from '../actions/userAction';

const INITIAL_STATE = { users: null, message: null, loading: false };

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case USERS.FETCH.REQUEST:
      return { ...state, loading: true };
    case USERS.FETCH.SUCCESS:
      return { ...state, users: payload, loading: false };
    case USERS.FETCH.FAILURE:
      return { ...state, loading: false, message: payload };
    default:
      return state;
  }
};

export default userReducer;
