import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const POST = 'POST';
const FETCH = 'FETCH';
const PATCH = 'PATCH';

export const PHONESNUMBER = createRequestTypes('PHONESNUMBER', [FETCH]);
export const PHONENUMBER = createRequestTypes('PHONENUMBER', [FETCH, POST, PATCH]);
export const PHONENUMBERSTATUS = createRequestTypes('PHONENUMBERSTATUS', [PATCH]);
export const CALIBRATEDPHONES = createRequestTypes('CALIBRATEDPHONES', [FETCH]);

export const getPhonesNumberAction = {
  request: (payload) => action(PHONESNUMBER.FETCH.REQUEST, payload),
  success: (data) => action(PHONESNUMBER.FETCH.SUCCESS, data),
  error: (data) => action(PHONESNUMBER.FETCH.ERROR, data),
  failure: (error) => action(PHONESNUMBER.FETCH.FAILURE, error),
};

export const getPhoneNumberAction = {
  request: (payload) => action(PHONENUMBER.FETCH.REQUEST, payload),
  success: (data) => action(PHONENUMBER.FETCH.SUCCESS, data),
  error: (data) => action(PHONENUMBER.FETCH.ERROR, data),
  failure: (error) => action(PHONENUMBER.FETCH.FAILURE, error),
};

export const getCalibratedPhonesAction = {
  request: (payload) => action(CALIBRATEDPHONES.FETCH.REQUEST, payload),
  success: (data) => action(CALIBRATEDPHONES.FETCH.SUCCESS, data),
  error: (data) => action(CALIBRATEDPHONES.FETCH.ERROR, data),
};

export const postPhoneNumberAction = {
  request: (payload) => action(PHONENUMBER.POST.REQUEST, payload),
  success: (data) => action(PHONENUMBER.POST.SUCCESS, data),
  failure: (error) => action(PHONENUMBER.POST.FAILURE, error),
};

export const patchPhoneNumberAction = {
  request: (payload) => action(PHONENUMBER.PATCH.REQUEST, payload),
  success: (data) => action(PHONENUMBER.PATCH.SUCCESS, data),
  failure: (error) => action(PHONENUMBER.PATCH.FAILURE, error),
};

export const patchPhoneNumberStatusAction = {
  request: (payload) => action(PHONENUMBERSTATUS.PATCH.REQUEST, payload),
  success: (data) => action(PHONENUMBERSTATUS.PATCH.SUCCESS, data),
  failure: (error) => action(PHONENUMBERSTATUS.PATCH.FAILURE, error),
};
