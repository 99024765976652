import React, { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Aside from '../Aside';
import NotAuthorizedAccess from '../NotAuthorizedAccess';
import HeaderContent from '../headerContent';
import { AuthContext } from '../../../utils/context/AuthContext';

const { Content } = Layout;

export const hasAccess = (role, path) => {
  const rolePermissions = {
    1: ['/dashboard', '/supervision', '/orders', '/settings', '/sounds'],
    2: ['/dashboard', '/supervision', '/orders', '/sounds'],
    3: ['/dashboard', '/supervision', '/sounds'],
    4: ['/dashboard', '/supervision', '/sounds'],
  };

  const allowedPaths = rolePermissions[role] || [];

  return allowedPaths.some((allowedPath) => path.startsWith(allowedPath));
};

const PrivateRoute = ({ children, headerTitle }) => {
  const { token, role } = useContext(AuthContext);
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const userHasAccess = hasAccess(role, pathname);

  if (!token) {
    return (
      <NotAuthorizedAccess
        token={token}
        reason={t('shared.authorization.notLogged')}
        redirectText={t('shared.authorization.redirectToLogin')}
        buttonText={t('shared.authorization.btnToLogin')}
        linkTo="/"
      />
    );
  }

  const content = userHasAccess
    ? children
    : (
      <NotAuthorizedAccess
        token={token}
        reason={t('shared.authorization.notAuthorized')}
        redirectText={t('shared.authorization.redirectToDashboard')}
        buttonText={t('shared.authorization.btnToDashboard')}
        linkTo="/dashboard"
      />
    );

  return (
    <Layout>
      <Aside />
      <Layout>
        <HeaderContent title={headerTitle} />
        <Content>
          { content }
        </Content>
      </Layout>
    </Layout>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  headerTitle: PropTypes.string,
};

PrivateRoute.defaultProps = {
  headerTitle: '',
};

export default PrivateRoute;
