import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const POST = 'POST';

export const LOGIN = createRequestTypes('LOGIN', [POST]);
export const LOGOUT = createRequestTypes('LOGOUT', [POST]);

export const loginAction = {
  request: () => action(LOGIN.POST.REQUEST),
  success: (data) => action(LOGIN.POST.SUCCESS, data),
  failure: (error) => action(LOGIN.POST.FAILURE, error),
};

export const logoutAction = {
  request: () => action(LOGOUT.POST.REQUEST),
  success: (data) => action(LOGOUT.POST.SUCCESS, data),
  failure: (error) => action(LOGOUT.POST.FAILURE, error),
};
