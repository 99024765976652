import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import fr from '../assets/langs/fr.json';
import en from '../assets/langs/en.json';
import es from '../assets/langs/es.json';

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    lng: 'fr',
    resources: {
      fr,
      en,
      es,
    },
    pluralSeparator: '_',
  });

export default i18n;
