import React from 'react';
import { Layout } from 'antd';
import imgFooter from '../../assets/images/leaktracker-footer.png';
import powered from '../../assets/images/powered-alcom.png';

const { Footer } = Layout;

const FooterComponent = () => (
  <Footer className="footer">
    <img className="footer-leak-bg" src={imgFooter} alt="Leak Tracker logo" />
    <img className="footer-leak-logo" src={powered} alt="Powered logo" />
  </Footer>
);

export default FooterComponent;
