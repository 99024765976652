import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';

export const ORDER = createRequestTypes('ORDER', [FETCH, POST]);
export const LASTORDER = createRequestTypes('LASTORDER', [FETCH]);
export const ORDERDETAILS = createRequestTypes('ORDERDETAILS', [PATCH]);
export const ORDERSTATUS = createRequestTypes('ORDERSTATUS', [PATCH]);

export const getOrdersAction = {
  request: (payload) => action(ORDER.FETCH.REQUEST, payload),
  success: (data) => action(ORDER.FETCH.SUCCESS, data),
  failure: (error) => action(ORDER.FETCH.FAILURE, error),
};

export const getLastOrderAction = {
  request: () => action(LASTORDER.FETCH.REQUEST),
  success: (data) => action(LASTORDER.FETCH.SUCCESS, data),
  failure: (error) => action(LASTORDER.FETCH.FAILURE, error),
};

export const postOrderAction = {
  request: (payload) => action(ORDER.POST.REQUEST, payload),
  success: (data) => action(ORDER.POST.SUCCESS, data),
  error: (error) => action(ORDER.POST.ERROR, error),
  failure: (failure) => action(ORDER.POST.FAILURE, failure),
};

export const patchOrderDetailsAction = {
  request: (payload) => action(ORDERDETAILS.PATCH.REQUEST, payload),
  success: (data) => action(ORDERDETAILS.PATCH.SUCCESS, data),
  failure: (error) => action(ORDERDETAILS.PATCH.FAILURE, error),
};

export const patchOrderStatusAction = {
  request: (payload) => action(ORDERSTATUS.PATCH.REQUEST, payload),
  success: (data) => action(ORDERSTATUS.PATCH.SUCCESS, data),
  failure: (error) => action(ORDERSTATUS.PATCH.FAILURE, error),
};
