import { Divider } from 'antd';
import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import AgencyForm from '../shared/Forms/AgencyForm';

const AgencySettings = () => {
  const navigate = useNavigate();
  return (
    <>
      <ArrowLeftOutlined className="come-back" onClick={() => navigate(-1)} />
      <Divider />
      <AgencyForm />
    </>

  );
};

export default AgencySettings;
