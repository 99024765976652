import { Divider, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { renderSelectValues } from '../../../../utils/utils';
import CompanyForm from '../../../shared/Forms/CompanyForm';

const OrderCompanyForm = ({
  currentCompanySelected,
  setCurrentCompanySelected,
}) => {
  const { companies } = useSelector((state) => state.companies);
  const { t } = useTranslation();

  const handleCompanySelectChange = (_, current) => {
    setCurrentCompanySelected(current);
  };

  return (
    <>
      <h3>{t('orders.form.selectCompany')}</h3>
      <Select
        value={currentCompanySelected}
        onChange={handleCompanySelectChange}
        className="mb-large"
        placeholder={t('orders.form.selectCompany')}
        options={renderSelectValues(companies)}
      />
      <Divider />
      <h4>{t('orders.form.createCompany')}</h4>
      <CompanyForm className="step-form" />
    </>
  );
};

OrderCompanyForm.propTypes = {
  currentCompanySelected: PropTypes.shape({}),
  setCurrentCompanySelected: PropTypes.func.isRequired,
};

OrderCompanyForm.defaultProps = {
  currentCompanySelected: null,
};

export default OrderCompanyForm;
