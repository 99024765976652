import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';

export const COMPANIES = createRequestTypes('COMPANIES', [FETCH]);
export const COMPANY = createRequestTypes('COMPANY', [POST, PATCH]);

export const getCompaniesAction = {
  request: (payload) => action(COMPANIES.FETCH.REQUEST, payload),
  success: (data) => action(COMPANIES.FETCH.SUCCESS, data),
  failure: (error) => action(COMPANIES.FETCH.FAILURE, error),
};

export const postCompanyAction = {
  request: (payload) => action(COMPANY.POST.REQUEST, payload),
  success: (data) => action(COMPANY.POST.SUCCESS, data),
  failure: (error) => action(COMPANY.POST.FAILURE, error),
};

export const patchCompanyAction = {
  request: (payload) => action(COMPANY.PATCH.REQUEST, payload),
  success: (data) => action(COMPANY.PATCH.SUCCESS, data),
  failure: (error) => action(COMPANY.PATCH.FAILURE, error),
};
