import React, {
  createContext, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import i18n from '../i18n';

export const Context = createContext();

const locales = {
  fr: frFR,
  en: enUS,
  es: esES,
};

const IntlContextProvider = ({ children }) => {
  const [initialLang, setInitialLang] = useState('fr');
  const [locale, setLocale] = useState(locales[initialLang]);

  useEffect(() => {
    setLocale(locales[initialLang]);
  }, [initialLang]);

  const value = useMemo(() => ({
    initialLang,
    setInitialLang,
  }), [initialLang]);

  return (
    <Context.Provider value={value}>
      <ConfigProvider locale={locale}>
        <I18nextProvider i18n={i18n}>
          {children}
        </I18nextProvider>
      </ConfigProvider>
    </Context.Provider>
  );
};

IntlContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IntlContextProvider;
