import { useMap } from 'react-leaflet';
import { useMemo } from 'react';

const ChangeMapPosition = ({ bounds }) => {
  const map = useMap();
  useMemo(() => {
    if (bounds && bounds.length > 0) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);
};

export default ChangeMapPosition;
