import { SOUNDS } from '../actions/soundsAction';

const INITIAL_STATE = {
  soundsList: null, loading: false, details: [],
};

const soundsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SOUNDS.FETCH.REQUEST:
      return { ...state, loading: true };
    case SOUNDS.FETCH.SUCCESS:
      return { ...state, soundsList: payload, loading: false };
    case SOUNDS.FETCH.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default soundsReducer;
