import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SynthesisCards from '../synthesisCards';
import SynthesisCharts from '../charts/SynthesisCharts';
import CustomDrawer from '../../drawer/CustomDrawer';

const SynthesisDrawer = ({
  isOpenDrawer, setIsOpenDrawer, currentRecord, type,
}) => {
  const { t } = useTranslation();

  const drawerTitle = currentRecord ? t('records.synthesisDrawer.title', {
    telephoneNumber: currentRecord.telephoneNumber,
    agencyName: currentRecord.agencyName,
    companyName: currentRecord.companyName,
    androidId: currentRecord.androidId,
  }) : '';

  return (
    <CustomDrawer
      placement="top"
      height={650}
      title={drawerTitle}
      isOpenDrawer={isOpenDrawer}
      setIsOpenDrawer={setIsOpenDrawer}
    >
      <SynthesisCards currentRecord={currentRecord} type={type} />
      <SynthesisCharts currentRecord={currentRecord} type={type} />
    </CustomDrawer>
  );
};

SynthesisDrawer.propTypes = {
  type: PropTypes.string,
  isOpenDrawer: PropTypes.bool,
  setIsOpenDrawer: PropTypes.func.isRequired,
  currentRecord: PropTypes.shape({
    telephoneNumber: PropTypes.string,
    agencyName: PropTypes.string,
    companyName: PropTypes.string,
    androidId: PropTypes.string,
  }),
};

SynthesisDrawer.defaultProps = {
  type: null,
  isOpenDrawer: false,
  currentRecord: null,
};

export default SynthesisDrawer;
