import { call, put, takeEvery } from 'redux-saga/effects';
import { getCanalSurfacesService, postCanalSurfaceService } from '../services/canalSurfaceService';
import { CANALSURFACE, getCanalSurfacesAction, postCanalSurfaceAction } from '../actions/canalSurfaceAction';
import { handleSagaErrorNotification, handleSagaSuccessNotification } from '../utils/responseHandler';
import { isProdEnv } from '../../utils/utils';

export function* getCanalSurfaces({ payload }) {
  const { token } = payload;
  try {
    const canalSurfaces = yield call(getCanalSurfacesService, token);
    const data = canalSurfaces?.data;

    if (data) {
      yield put(getCanalSurfacesAction.success(data));
    }
  } catch (err) {
    if (!isProdEnv) {
      handleSagaErrorNotification(err, 'canalSurface');
    }
    yield put(getCanalSurfacesAction.failure(err));
  }
}

export function* postCanalSurface({ payload }) {
  const token = payload[1];
  try {
    const canalSurfaceResponse = yield call(postCanalSurfaceService, payload);
    const canalSurfaces = yield call(getCanalSurfacesService, token);

    if (canalSurfaceResponse) {
      const descriptionSuccess = 'shared.notification.canal.successPostSurface';
      yield put(postCanalSurfaceAction.success(descriptionSuccess));
      yield put(getCanalSurfacesAction.success(canalSurfaces.data));
      yield put(postCanalSurfaceAction.failure(null));
      handleSagaSuccessNotification(descriptionSuccess);
    }
  } catch (err) {
    handleSagaErrorNotification(err, 'canalSurface');
    yield put(postCanalSurfaceAction.failure(err));
  }
}

export function* canalSurfacesSaga() {
  yield takeEvery(CANALSURFACE.FETCH.REQUEST, getCanalSurfaces);
  yield takeEvery(CANALSURFACE.POST.REQUEST, postCanalSurface);
}
