import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MobileOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TableTemplate from '../shared/TableTemplate';
import DashboardList from '../shared/SharedStyle';
import HeaderList from './agenciesDetails/HeaderList';
import { getPhonesNumberAction } from '../../business/actions/mobileAction';
import { renderTags } from '../../utils/utils';

const MobilesByAgencyList = () => {
  const { agencies } = useSelector((state) => state.agencies);
  const { mobiles } = useSelector((state) => state.mobiles);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();

  const renderData = () => {
    const data = [];
    agencies?.map((agency) => {
      data.push({
        key: agency.id,
        name: agency.name,
        mobiles: agency.id,
      });
      return null;
    });
    setTableData(data);
  };

  useEffect(() => {
    renderData();
  }, [mobiles]);

  const columns = [
    {
      title: t('dashboard.mobilesByAgency.name'),
      dataIndex: 'name',
      key: 'name',
      responsive: ['md', 'lg'],
      width: 60,
    },
    {
      title: t('dashboard.mobilesByAgency.numberMobile'),
      dataIndex: 'mobiles',
      key: 'mobiles',
      render: (text) => mobiles?.map((mobile) => {
        const { id, agencyId, telephoneNumber } = mobile;
        if (agencyId === text) {
          return renderTags(id, <MobileOutlined />, telephoneNumber);
        }
        return null;
      }),
      responsive: ['md', 'lg'],
      width: 70,
    },
  ];

  return (
    <DashboardList width="650px">
      <HeaderList
        name={t('dashboard.mobilesByAgency.title')}
        stateToUpdate={getPhonesNumberAction}
        visibleUpdateData
      />
      <TableTemplate columns={columns} data={tableData} />
    </DashboardList>
  );
};

export default MobilesByAgencyList;
