import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';

export const MONITOR = createRequestTypes('MONITOR', [FETCH]);

export const getMonitorAction = {
  request: (payload) => action(MONITOR.FETCH.REQUEST, payload),
  success: (data) => action(MONITOR.FETCH.SUCCESS, data),
  failure: (error) => action(MONITOR.FETCH.FAILURE, error),
};
