import api from '../utils/query';

const baseUrl = 'distributor';

export const getDistributorsService = (token) => api.get(baseUrl, token);

export const getDistributorsDetailsService = (token) => {
  const apiUrl = `${baseUrl}/details`;
  return api.get(apiUrl, token);
};

export const getDistributorsByUserService = (token) => {
  const apiUrl = `${baseUrl}/user`;
  return api.get(apiUrl, token);
};

export const postDistributorService = (data) => api.create(baseUrl, data);

export const patchDistributorService = (data) => {
  const { id } = data;
  const apiUrl = `${baseUrl}/${id}`;
  return api.update(apiUrl, data);
};
