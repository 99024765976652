import PropTypes from 'prop-types';
import PrintButton from '../PrintButton';

const MobileInfosTitle = ({ title }) => (
  <section className="mobile-infos-title">
    <span>{title}</span>
    <PrintButton />
  </section>
);

MobileInfosTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default MobileInfosTitle;
