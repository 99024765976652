import { notification } from 'antd';
import i18n from 'i18next';
import { isProdEnv } from '../../utils/utils';

const formatErrorDescription = (err) => {
  if (err.response) {
    const { status } = err.response;
    const statusDescription = {
      400: 'shared.notification.failure.status.400',
      401: 'shared.notification.failure.status.401',
      403: 'shared.notification.failure.status.403',
      404: 'shared.notification.failure.status.404',
      500: 'shared.notification.failure.status.500',
    };
    return statusDescription[status] || i18n.t(`shared.notification.failure.title.UnexpectedErrorOccurred : ${status} `);
  }
  return err.message || i18n.t('shared.notification.failure.title.UnexpectedErrorOccurred');
};

export const handleSagaErrorNotification = (err, origin) => {
  const isAuthError = err.response?.status === 401 && origin === 'auth';
  if (isAuthError) {
    const description = i18n.t('shared.notification.auth.incorrectCredentials');
    notification.warning({ description });
  } else if (isProdEnv) {
    const description = err.response?.status === 400
      ? i18n.t('shared.notification.failure.title.400')
      : i18n.t('shared.notification.failure.title.tryAgain');
    notification.warning({ description });
  } else {
    const message = i18n.t(`shared.notification.failure.title.${origin}`);
    const description = i18n.t(formatErrorDescription(err));
    notification.warning({ message, description });
  }
};

export const handleSagaSuccessNotification = (descriptionSuccess) => {
  const message = i18n.t('shared.notification.success');
  const description = i18n.t(descriptionSuccess);
  notification.success({ message, description });
};
