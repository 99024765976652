import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Monitoring from './Monitoring';
import { PageContainer } from '../shared/SharedStyle';
import { getAgenciesDetailsAction } from '../../business/actions/agencyAction';

const Supervision = () => {
  const { analysis } = useSelector((state) => state.analysis);
  const dispatch = useDispatch();

  useEffect(() => {
    if (analysis) {
      dispatch(getAgenciesDetailsAction.request(analysis));
    }
  }, []);

  return (
    <PageContainer className="supervision" screenHeight={window.innerHeight}>
      <Monitoring />
    </PageContainer>
  );
};

export default Supervision;
