import { MONITOR } from '../actions/monitoringAction';

const INITIAL_STATE = {
  soundsType: null, loading: false,
};

const monitoringReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MONITOR.FETCH.REQUEST:
      return { ...state, loading: true };
    case MONITOR.FETCH.SUCCESS:
      return { ...state, soundsType: payload, loading: false };
    case MONITOR.FETCH.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default monitoringReducer;
