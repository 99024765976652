import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShowRowsInfo = ({ expandedRowKeys, toggleShowAllRowsInfo }) => {
  const { t } = useTranslation();

  return (
    <div className="hidden-info-sounds">
      {expandedRowKeys.length > 0
        ? (
          <Button className="button-reset" onClick={toggleShowAllRowsInfo}>
            <EyeInvisibleOutlined />
            {t('records.hideAllInfos')}
          </Button>
        )
        : (
          <Button className="button-reset" onClick={toggleShowAllRowsInfo}>
            <EyeTwoTone />
            {t('records.displayAllInfos')}
          </Button>
        )}
    </div>
  );
};

ShowRowsInfo.propTypes = {
  expandedRowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleShowAllRowsInfo: PropTypes.func.isRequired,
};

export default ShowRowsInfo;
