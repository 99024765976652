import React from 'react';
import { monitoringComponents } from '../../../../utils/utils';
import IndicatorCard from './IndicatorCard';

const sortedMonitoringComponents = [...monitoringComponents].sort((a, b) => a.order - b.order);

const IndicatorCards = () => (
  <article className="indicator-cards">
    {sortedMonitoringComponents?.map((item) => {
      if (item.type === 'indicator') {
        return (
          <IndicatorCard
            key={item.id}
            fill={item.fill}
            title={item.title}
            source={item.source}
          />
        );
      }
      return null;
    })}
  </article>
);

export default IndicatorCards;
