import { AUTH } from '../actions/authAction';

const INITIAL_STATE = { token: null, loading: true };

const authReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH.POST.REQUEST:
      return { ...state, loading: true };
    case AUTH.POST.SUCCESS:
      return { ...state, token: payload, loading: false };
    case AUTH.POST.FAILURE:
      return { ...state, token: payload, loading: false };
    default:
      return state;
  }
};

export default authReducer;
