import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const POST = 'POST';

export const AUTH = createRequestTypes('AUTH', [POST]);

export const postAuthAction = {
  request: (data) => action(AUTH.POST.REQUEST, data),
  success: (data) => action(AUTH.POST.SUCCESS, data),
  failure: (error) => action(AUTH.POST.FAILURE, error),
};
