import { call, put, takeEvery } from 'redux-saga/effects';
import { handleSagaErrorNotification, handleSagaSuccessNotification } from '../utils/responseHandler';
import {
  getDistributorsByUserService,
  getDistributorsService,
  patchDistributorService,
  postDistributorService,
} from '../services/distributorService';
import {
  DISTRIBUTOR,
  DISTRIBUTORS,
  getDistributorsAction,
  patchDistributorAction,
  postDistributorAction,
} from '../actions/distributorAction';
import { patchOrderDetailsAction } from '../actions/orderAction';

const selectGetDistributorsService = (role) => (
  role !== 1 ? getDistributorsByUserService : getDistributorsService
);

export function* getDistributors({ payload }) {
  const { showError, token, role } = payload;
  const service = selectGetDistributorsService(role);
  try {
    const distributors = yield call(service, token);
    const data = distributors?.data;

    if (data) {
      yield put(getDistributorsAction.success(data));
    }
  } catch (err) {
    if (showError) {
      handleSagaErrorNotification(err, 'distributor');
    }
    yield put(getDistributorsAction.failure(err));
  }
}

export function* postDistributor({ payload }) {
  const { token } = payload;
  try {
    const distributorResponse = yield call(postDistributorService, payload);
    if (distributorResponse) {
      const distributors = yield call(getDistributorsService, token);
      const descriptionSuccess = 'shared.notification.distributor.successAdd';
      yield put(getDistributorsAction.success(distributors.data));
      yield put(postDistributorAction.success(descriptionSuccess));
      yield put(postDistributorAction.failure(null));
      handleSagaSuccessNotification(descriptionSuccess);
    }
  } catch (err) {
    handleSagaErrorNotification(err, 'distributor');
    yield put(postDistributorAction.failure(err));
  }
}

export function* patchDistributor({ payload }) {
  const { token, role } = payload;
  const getService = selectGetDistributorsService(role);
  try {
    const distributorResponse = yield call(patchDistributorService, payload);

    if (distributorResponse) {
      const descriptionSuccess = 'shared.notification.distributor.successUpdate';
      yield put(patchDistributorAction.success(descriptionSuccess));
      const newDistributors = yield call(getService, token);
      yield put(getDistributorsAction.success(newDistributors.data));
      handleSagaSuccessNotification(descriptionSuccess);
    }
  } catch (err) {
    handleSagaErrorNotification(err, 'distributor');
    yield put(patchOrderDetailsAction.failure(err));
  }
}

export function* distributorsSaga() {
  yield takeEvery(DISTRIBUTORS.FETCH.REQUEST, getDistributors);
  yield takeEvery(DISTRIBUTOR.POST.REQUEST, postDistributor);
  yield takeEvery(DISTRIBUTOR.PATCH.REQUEST, patchDistributor);
}
