import { postDistributorAction } from '../../../business/actions/distributorAction';
import EntityFormTemplate from './EntityFormTemplate';

const DistributorForm = () => (
  <EntityFormTemplate
    type="distributor"
    postAction={postDistributorAction}
  />
);
export default DistributorForm;
