import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatTimeAgo } from '../../utils/utils';
import { Context } from '../../utils/context/Intlcontext';

const LastListen = ({ lastListen }) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const lang = context?.initialLang;
  const noRecord = t('monitoring.noRecord');

  return (
    <div className="last-listen">
      <span>{t('monitoring.lastListening')}</span>
      <span className="last-listen-time">
        {formatTimeAgo(lastListen, lang, noRecord)}
      </span>
    </div>
  );
};

LastListen.propTypes = {
  lastListen: PropTypes.number,
};

LastListen.defaultProps = {
  lastListen: 0,
};

export default LastListen;
