import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CounterCard = ({ title, source }) => {
  const { agencies } = useSelector((state) => state.agencies);
  const { companies } = useSelector((state) => state.companies);
  const { mobiles, calibrationStatus } = useSelector((state) => state.mobiles);
  const { t } = useTranslation();

  let count;
  switch (source) {
    case 'agencies':
      count = agencies?.length;
      break;
    case 'companies':
      count = companies?.length;
      break;
    case 'mobiles':
      count = mobiles?.length;
      break;
    case 'calibratedPhones':
      count = calibrationStatus?.calibrated;
      break;
    case 'calibratedInProcess':
      count = calibrationStatus?.inProcess;
      break;
    default:
      count = null;
  }

  return (
    <div className="counter-card">
      <h3 className="counter-card-title">
        {t(`monitoring.counter.${title}`)}
      </h3>
      <span className="counter-card-count">{count}</span>
    </div>
  );
};

CounterCard.propTypes = {
  title: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default CounterCard;
