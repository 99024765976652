import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const LineChart = ({
  data,
  xTitle,
  yTitle,
  borderColor,
  backgroundColor,
  title,
  height,
  width,
  pointRadius,
  borderWidth,
}) => {
  const labels = Array.from({ length: data.length }, (value, index) => index + 1);

  const chartOptions = {
    plugins: {
      title: {
        display: true,
        text: title,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        title: {
          display: true,
          text: xTitle,
        },
      },
      y: {
        title: {
          display: true,
          text: yTitle,
        },
      },
    },
    maintainAspectRatio: false,
  };

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Vecteur',
        data,
        borderColor,
        backgroundColor,
        pointRadius,
        borderWidth,
      },
    ],
  };

  return (
    <div className="chart">
      <Line
        data={chartData}
        options={chartOptions}
        width={width}
        height={height}
      />
    </div>
  );
};

LineChart.propTypes = {
  pointRadius: PropTypes.number.isRequired,
  borderWidth: PropTypes.number.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  xTitle: PropTypes.string.isRequired,
  yTitle: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

LineChart.defaultProps = {
  width: 400,
  height: 350,
};

export default LineChart;
