import React from 'react';
import { useTranslation } from 'react-i18next';

const NoData = () => {
  const { t } = useTranslation();
  return (
    <span>{t('shared.chart.noData')}</span>
  );
};

export default NoData;
