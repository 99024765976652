import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AgenciesList from './AgenciesList';
import CompaniesList from './CompaniesList';
import { getCompaniesAction } from '../../business/actions/companyAction';
import { getAgenciesAction } from '../../business/actions/agencyAction';
import { PageContainer } from '../shared/SharedStyle';
import { getPhonesNumberAction } from '../../business/actions/mobileAction';
import MobilesList from './MobilesList';
import AgenciesDetails from './agenciesDetails';
import { getRecordsAction } from '../../business/actions/recordsAction';
import SkeletonList from '../shared/skeletons/SkeletonList';
import MobilesByAgencyList from './MobilesByAgencyList';
import { getAnalysisAction, getAnalysisDetailsAction } from '../../business/actions/analysisAction';
import { isProdEnv } from '../../utils/utils';
import { AuthContext } from '../../utils/context/AuthContext';
import DistributorsList from './DistributorsList';
import { getDistributorsAction } from '../../business/actions/distributorAction';
import TripletStats from './TripletStats';

const Dashboard = () => {
  const { token, role } = useContext(AuthContext);
  const { analysis, message, loading: analysisLoading } = useSelector((state) => state.analysis);
  const { recordsList } = useSelector((state) => state.rawRecords);
  const { loading: companiesLoading } = useSelector((state) => state.companies);
  const { agencies, loading: agenciesLoading } = useSelector((state) => state.agencies);
  const { loading: mobilesLoading } = useSelector((state) => state.mobiles);
  const { loading: distributorsLoading } = useSelector((state) => state.distributors);
  const isAllCompaniesDataLoaded = !companiesLoading && !agenciesLoading;
  const isMobilesByAgencyLoaded = !mobilesLoading && !agenciesLoading;
  const isAllDistributorsLoaded = !distributorsLoading && !companiesLoading;
  const dispatch = useDispatch();

  const componentsList = [
    {
      id: 1,
      isLoaded: !analysisLoading,
      component: <TripletStats />,
      roles: [1],
    },
    {
      id: 3,
      isLoaded: isAllDistributorsLoaded,
      component: <DistributorsList />,
      roles: [1, 2],
    },
    {
      id: 5,
      isLoaded: isAllCompaniesDataLoaded,
      component: <CompaniesList />,
      roles: [1, 2],
    },
    {
      id: 10,
      isLoaded: !agenciesLoading,
      component: <AgenciesList />,
      roles: [1, 2, 3],
    },
    {
      id: 20,
      isLoaded: isMobilesByAgencyLoaded,
      component: (
        <>
          <MobilesByAgencyList />
          <MobilesList />
        </>
      ),
      skeletonProps: { width: '600', rows: 3 },
      roles: [1, 2, 3, 4],
    },
  ];

  useEffect(() => {
    if (analysis === null && !message) {
      dispatch(getAnalysisAction.request({ showError: !isProdEnv, token, role }));
      dispatch(getAgenciesAction.request({ showError: !isProdEnv, token, role }));
      dispatch(getCompaniesAction.request({ showError: !isProdEnv, token, role }));
      dispatch(getDistributorsAction.request({ showError: !isProdEnv, token, role }));
      dispatch(getRecordsAction.request({ token, role }));
    } else if (recordsList) {
      dispatch(getAnalysisDetailsAction.request({ analysis, recordsList }));
    }
  }, [analysis, recordsList, message]);

  useEffect(() => {
    if (agencies) {
      dispatch(getPhonesNumberAction.request({ agencies, token, role }));
    }
  }, [agencies]);

  const renderComponentsList = () => componentsList
    .filter((item) => item.roles.includes(role))
    .map((item) => {
      const { width, rows } = item.skeletonProps || {};
      return item.isLoaded
        ? <React.Fragment key={item.id}>{item.component}</React.Fragment>
        : <SkeletonList key={item.id} width={width} rows={rows} />;
    });

  return (
    <PageContainer className="dashboard" screenHeight={window.innerHeight}>
      <AgenciesDetails />
      {renderComponentsList()}
    </PageContainer>
  );
};

export default Dashboard;
