import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledWaveSVG } from '../../SharedStyle';
import { getSourceState } from '../../../../utils/utils';

const IndicatorCard = ({ title, source, fill }) => {
  const [soundsCount, setSoundsCount] = useState();
  const { soundsType } = useSelector((state) => state.monitoring);
  const { t } = useTranslation();

  useEffect(() => {
    if (soundsType) {
      const count = getSourceState(source, soundsType);
      setSoundsCount(count);
    }
  }, [soundsType]);

  let transformValue = '';

  if (title === 'leak') {
    transformValue = 'translate(-13180.552, -1870.387)';
  } else if (title === 'noLeak') {
    transformValue = 'translate(-13070.552, -1860.387)';
  } else {
    transformValue = 'translate(-13070.552 -1855.387)';
  }

  return (
    <div className="indicator-card">
      <h2 className="indicator-card-title">
        {t(`monitoring.indicator.${title}`)}
      </h2>
      <span className="indicator-card-count">{soundsCount}</span>
      <StyledWaveSVG fill={fill} xmlns="http://www.w3.org/2000/svg" width="444.871" height="192.233" viewBox="0 0 444.871 192.233">
        <path id="Tracé_469" data-name="Tracé 469" d="M13063,1993.929s70.681-21.522,176.426,31.46,256-84,256-84V2133.62h-444.871Z" transform={transformValue} />
      </StyledWaveSVG>
    </div>
  );
};

IndicatorCard.propTypes = {
  title: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export default IndicatorCard;
