import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const NotAuthorizedAccess = ({
  token,
  reason,
  redirectText,
  buttonText,
  linkTo,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectPath = token ? '/dashboard' : '/';
    const timeout = setTimeout(() => {
      navigate(redirectPath);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [token, navigate]);

  return (
    <section className="notAuthorizedAccess">
      <h1>{t('shared.authorization.oops')}</h1>
      <h2>{reason}</h2>
      <p>
        {t('shared.authorization.redirectIn5s', { page: redirectText })}
        <Spin />
      </p>
      <Link to={linkTo}>
        <Button className="btn btn-primary">{buttonText}</Button>
      </Link>
    </section>
  );
};

NotAuthorizedAccess.propTypes = {
  token: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  redirectText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default NotAuthorizedAccess;
