import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';
const PATCH = 'PATCH';

export const AGENCIES = createRequestTypes('AGENCIES', [FETCH]);
export const AGENCIESDETAILS = createRequestTypes('AGENCIESDETAILS', [FETCH]);
export const AGENCY = createRequestTypes('AGENCY', [POST, PATCH]);

export const getAgenciesAction = {
  request: (payload) => action(AGENCIES.FETCH.REQUEST, payload),
  success: (data) => action(AGENCIES.FETCH.SUCCESS, data),
  failure: (error) => action(AGENCIES.FETCH.FAILURE, error),
};

export const getAgenciesDetailsAction = {
  request: (payload) => action(AGENCIESDETAILS.FETCH.REQUEST, payload),
  success: (data) => action(AGENCIESDETAILS.FETCH.SUCCESS, data),
  failure: (error) => action(AGENCIESDETAILS.FETCH.FAILURE, error),
};

export const postAgencyAction = {
  request: (payload) => action(AGENCY.POST.REQUEST, payload),
  success: (data) => action(AGENCY.POST.SUCCESS, data),
  failure: (error) => action(AGENCY.POST.FAILURE, error),
};

export const patchAgencyAction = {
  request: (payload) => action(AGENCY.PATCH.REQUEST, payload),
  success: (data) => action(AGENCY.PATCH.SUCCESS, data),
  failure: (error) => action(AGENCY.PATCH.FAILURE, error),
};
