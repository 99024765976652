import { COMPANIES, COMPANY } from '../actions/companyAction';

const INITIAL_STATE = { companies: null, message: null, loading: false };

const companyReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case COMPANIES.FETCH.REQUEST:
      return { ...state, loading: true };
    case COMPANIES.FETCH.SUCCESS:
      return { ...state, companies: payload, loading: false };
    case COMPANIES.FETCH.FAILURE:
      return { ...state, loading: false };
    case COMPANY.POST.REQUEST:
      return { ...state, loading: true };
    case COMPANY.POST.SUCCESS:
      return { ...state, message: payload, loading: false };
    case COMPANY.POST.FAILURE:
      return { ...state, loading: false };
    case COMPANY.PATCH.REQUEST:
      return { ...state, loading: true };
    case COMPANY.PATCH.SUCCESS:
      return { ...state, loading: false };
    case COMPANY.PATCH.FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default companyReducer;
