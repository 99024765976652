import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { formatDateOrNA, getStatusText } from '../../../../utils/utils';

const ReviewStep = ({ currentCompanySelected, getLatestOrderStatus }) => {
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const { lastOrder } = useSelector((state) => state.orders);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentCompanySelected && lastOrder) {
      const {
        startTimestamp,
        expiryTimestamp,
        orderNumber,
        invoiceNumber,
        quoteNumber,
        totalAmount,
        companyName,
        renewalDuration,
        status,
        licenceLimit,
      } = lastOrder;
      const lastStatus = getLatestOrderStatus(status);
      const lastStatusText = getStatusText(lastStatus, t);
      setDataToDisplay({
        [t('orders.review.company')]: companyName,
        [t('orders.review.quoteNumber')]: quoteNumber,
        [t('orders.review.totalAmount')]: totalAmount,
        [t('orders.review.licenceLimit')]: licenceLimit,
        [t('orders.review.invoiceNumber')]: invoiceNumber || '--',
        [t('orders.review.orderNumber')]: orderNumber || '--',
        [t('orders.review.duration')]: renewalDuration,
        [t('orders.review.startDate')]: formatDateOrNA(startTimestamp, t('format.date')),
        [t('orders.review.expiryDate')]: formatDateOrNA(expiryTimestamp, t('format.date')),
        [t('orders.review.status')]: lastStatusText,
      });
    }
  }, [currentCompanySelected, lastOrder]);

  const data = Object.entries(dataToDisplay);

  const renderAllValues = () => data?.map(([key, value]) => (
    <section key={key} className="review-order-item">
      <article className="review-order-item-content">
        <span className="text-bold">
          {t(`orders.review.${key}`, key)}
          {' '}
          :
        </span>
        <span>{value}</span>
      </article>
      <Divider />
    </section>
  ));

  return (
    <section className="review-order">
      <h3 className="mb-40">{t('orders.review.title')}</h3>
      {renderAllValues()}
    </section>
  );
};

ReviewStep.propTypes = {
  currentCompanySelected: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }),
  getLatestOrderStatus: PropTypes.func.isRequired,
};

ReviewStep.defaultProps = {
  currentCompanySelected: null,
};

export default ReviewStep;
