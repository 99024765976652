import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import OrderCompanyForm from './OrderCompanyForm';
import OrderForm from '../../../shared/Forms/OrderForm';
import ReviewStep from './ReviewStep';
import StepsTemplate from '../../../shared/StepsTemplate';

const OrderSteps = ({ setOpenDrawer, getLatestOrderStatus }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [orderDetailsData, setOrderDetailsData] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentCompanySelected, setCurrentCompanySelected] = useState(null);
  const { t } = useTranslation();

  const isCompanyStepDisabled = currentStep === 0 && !currentCompanySelected;
  const isOrderStepDisabled = currentStep === 1 && !isFormValid;
  const isNextButtonDisabled = isCompanyStepDisabled || isOrderStepDisabled;

  const steps = [
    {
      title: t('orders.steps.company'),
      content: (
        <OrderCompanyForm
          currentCompanySelected={currentCompanySelected}
          setCurrentCompanySelected={setCurrentCompanySelected}
        />
      ),
    },
    {
      title: t('orders.steps.order'),
      content: (
        <OrderForm
          setIsFormValid={setIsFormValid}
          setOrderDetailsData={setOrderDetailsData}
          orderDetailsData={orderDetailsData}
          currentCompanySelected={currentCompanySelected}
          setCurrentStep={setCurrentStep}
          getLatestOrderStatus={getLatestOrderStatus}
        />
      ),
    },
    {
      title: t('orders.steps.end'),
      content: (
        <ReviewStep
          currentCompanySelected={currentCompanySelected}
          orderDetailsData={orderDetailsData}
          setOpenDrawer={setOpenDrawer}
          getLatestOrderStatus={getLatestOrderStatus}
        />
      ),
    },
  ];

  return (
    <StepsTemplate
      currentStep={currentStep}
      steps={steps}
      setCurrentStep={setCurrentStep}
      isNextButtonDisabled={isNextButtonDisabled}
      setOpenDrawer={setOpenDrawer}
    />
  );
};

OrderSteps.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  getLatestOrderStatus: PropTypes.func.isRequired,
};

export default OrderSteps;
