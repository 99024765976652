import React from 'react';
import {
  FolderOutlined, HomeOutlined, MobileOutlined, ToolOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const menu = [
    {
      label: t('settings.distributor.add'),
      key: 'distributor',
      picto: <HomeOutlined className="settings-item-picto" />,
      link: 'distributor',
    },
    {
      label: t('settings.company.addCompany'),
      key: 'company',
      picto: <HomeOutlined className="settings-item-picto" />,
      link: 'company',
    },
    {
      label: t('settings.agency.addAgency'),
      key: 'agency',
      picto: <HomeOutlined className="settings-item-picto" />,
      link: 'agency',
    },
    {
      label: t('settings.mobile.addNumber'),
      key: 'mobile',
      picto: <MobileOutlined className="settings-item-picto" />,
      link: 'mobile',
    },
    {
      label: t('settings.canal.add'),
      key: 'canal',
      picto: <ToolOutlined className="settings-item-picto" />,
      link: 'canal',
    },
    {
      label: t('settings.archives.title'),
      key: 'archives',
      picto: <FolderOutlined className="settings-item-picto" />,
      link: 'archives',
    },
  ];

  const handleClick = (elt) => {
    navigate(elt);
  };

  const renderFormsSetting = () => menu.map((elt) => (
    <li
      className="settings-item"
      key={elt.key}
      onClick={() => handleClick(elt.link)}
      aria-hidden="true"
    >
      {elt.picto}
      <span>{elt.label}</span>
    </li>
  ));

  return (
    <Layout className="settings">
      <ul className="block-menu">{renderFormsSetting()}</ul>
    </Layout>
  );
};

export default Settings;
