import React, { useContext, useState } from 'react';
import { Button, Popconfirm, message } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { patchOrderStatusAction } from '../../../../business/actions/orderAction';
import { AuthContext } from '../../../../utils/context/AuthContext';

const PopConfirmStatusUpdate = ({
  item, color, label, currentOrder,
}) => {
  const [statusToUpdate, setStatusToUpdate] = useState();
  const { token } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const confirm = () => {
    dispatch(patchOrderStatusAction.request({ id: currentOrder, status: statusToUpdate, token }));
    message.success(t('orders.popConfirm.messageOk'));
  };

  const cancel = () => {
    message.error(t('orders.popConfirm.messageCancel'));
    setStatusToUpdate(null);
  };

  const handleUpdateStatus = (status) => {
    setStatusToUpdate(status);
  };

  return (
    <Popconfirm
      title={t('orders.popConfirm.title')}
      description={t('orders.popConfirm.description')}
      onConfirm={confirm}
      onCancel={cancel}
      okText={t('orders.popConfirm.ok')}
      cancelText={t('orders.popConfirm.cancel')}
    >
      <Button
        type="text"
        className={`clickable ${color}`}
        onClick={() => handleUpdateStatus(item)}
      >
        {label}
      </Button>
    </Popconfirm>
  );
};

PopConfirmStatusUpdate.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.number,
  }).isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  currentOrder: PropTypes.string.isRequired,
};

export default PopConfirmStatusUpdate;
