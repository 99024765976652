import action from '../utils/actionConf';
import createRequestTypes from '../utils/createRequestTypes';

const FETCH = 'FETCH';
const POST = 'POST';

export const CANALSURFACE = createRequestTypes('CANALSURFACE', [FETCH, POST]);

export const getCanalSurfacesAction = {
  request: (payload) => action(CANALSURFACE.FETCH.REQUEST, payload),
  success: (data) => action(CANALSURFACE.FETCH.SUCCESS, data),
  error: (message) => action(CANALSURFACE.FETCH.ERROR, message),
  failure: (error) => action(CANALSURFACE.FETCH.FAILURE, error),
};

export const postCanalSurfaceAction = {
  request: (payload) => action(CANALSURFACE.POST.REQUEST, payload),
  success: (data) => action(CANALSURFACE.POST.SUCCESS, data),
  error: (message) => action(CANALSURFACE.POST.ERROR, message),
  failure: (error) => action(CANALSURFACE.POST.FAILURE, error),
};
