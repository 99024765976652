import { Button, Divider, Steps } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { handleStepChange } from '../../utils/utils';

const StepsTemplate = ({
  currentStep,
  steps,
  setCurrentStep,
  isNextButtonDisabled,
  className,
  setOpenDrawer,
}) => {
  const { t } = useTranslation();
  const isLastStep = currentStep === steps.length - 1;
  const isOrderStep = currentStep === steps.length - 2;

  const handleFinishStep = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      setCurrentStep(0);
    }, 1000);
  };

  const handleBackStep = () => handleStepChange(setCurrentStep, 'prev');
  const handleNextStep = () => (isLastStep ? handleFinishStep() : handleStepChange(setCurrentStep, 'next'));

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <Steps
        className={className}
        current={currentStep}
        items={items}
      />
      <Divider />
      <section>{steps[currentStep].content}</section>
      <>
        {currentStep > 0 && !isLastStep && (
          <Button
            icon={<LeftOutlined />}
            className="back-btn-step button-reset"
            onClick={handleBackStep}
          >
            {t('orders.steps.backBtn')}
          </Button>

        )}
        {!isOrderStep && (
          <Button
            disabled={isNextButtonDisabled}
            className="next-btn-step button-reset"
            onClick={handleNextStep}
          >
            {isLastStep ? t('orders.steps.close') : (
              <>
                {t('orders.steps.nextBtn')}
                <RightOutlined className="ml-small" />
              </>
            )}
          </Button>
        )}
      </>
    </>
  );
};

StepsTemplate.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired,
  })).isRequired,
  setOpenDrawer: PropTypes.func,
  setCurrentStep: PropTypes.func.isRequired,
  isNextButtonDisabled: PropTypes.bool,
  className: PropTypes.string,
};

StepsTemplate.defaultProps = {
  setOpenDrawer: () => {},
  isNextButtonDisabled: false,
  className: '',
};

export default StepsTemplate;
