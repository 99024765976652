import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MapContainer, Marker, TileLayer,
  Tooltip,
} from 'react-leaflet';
import { Icon } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { formatDate } from '../../../utils/utils';
import waterRed from '../../../assets/images/water-red.png';
import waterBlue from '../../../assets/images/water-blue.png';
import waterGrey from '../../../assets/images/water-grey.png';
import 'leaflet/dist/leaflet.css';
import ChangeMapPosition from './ChangeMapPosition';

const CheckboxGroup = Checkbox.Group;
const options = ['Fuites', 'sans fuites', 'qualifiés'];
const defaultCheckedList = ['Fuites', 'sans fuites'];

const ATTRIBUTION = process.env.REACT_APP_MAP_ATTRIBUTION;
const URL = process.env.REACT_APP_MAP_URL;

const MapLeak = ({
  data,
  isMiniMap,
  className,
  currentHoverRowList,
}) => {
  const [bounds, setBounds] = useState();
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [showLeak, setShowLeak] = useState(true);
  const [showLeakLess, setShowLeakLess] = useState(true);
  const [showQualify, setShowQualify] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setBounds(data?.map((item) => [item.latitude, item.longitude]));
  }, [data]);

  const onChange = (list) => {
    const hasLeak = list.some((item) => item === 'Fuites');
    const hasLeakLess = list.some((item) => item === 'sans fuites');
    const hasQualify = list.some((item) => item === 'qualifiés');
    setShowLeak(hasLeak);
    setShowLeakLess(hasLeakLess);
    setShowQualify(hasQualify);
    setCheckedList(list);
  };

  const renderCheckBox = () => (
    <article className="map-record-control">
      <CheckboxGroup
        options={options}
        value={checkedList}
        onChange={onChange}
      />
    </article>
  );

  const renderMarkers = () => data?.reverse().map((item) => {
    const showTooltip = (currentHoverRowList?.key === item.key && !isMiniMap);
    const isQualified = item.qualification < 2;
    const isLeak = item.decision === 1;
    const isLeakLess = item.decision === 0;
    const allLeak = showLeak && isLeak;
    const allLeakLess = showLeakLess && isLeakLess;
    const allLeakQualified = allLeak && isQualified;
    const allLeakLessQualified = allLeakLess && isQualified;
    const allQualified = (isLeak || isLeakLess) && isQualified;

    const showMarker = (res) => {
      const leakClass = res === 1 ? 'leak-marker bounce' : '';
      if (allLeak && !showQualify) {
        return `leak-marker ${leakClass}`;
      }
      if (allLeakLess && !showQualify) {
        return `leak-marker ${leakClass}`;
      }
      if (allQualified && showQualify && showLeak && showLeakLess) {
        return `leak-marker ${leakClass}`;
      }
      if (allLeakQualified && showQualify) {
        return `leak-marker ${leakClass}`;
      }
      if (allLeakLessQualified && showQualify) {
        return `leak-marker ${leakClass}`;
      }

      return 'desactivated';
    };
    let iconUrl = null;
    let nameClass = '';

    switch (item.decision) {
      case 1:
        iconUrl = waterRed;
        nameClass = showMarker(1);
        break;
      case undefined:
      case null:
        iconUrl = waterGrey;
        nameClass = showMarker(2);
        break;
      default:
        iconUrl = waterBlue;
        nameClass = showMarker(0);
    }
    const customIcon = new Icon({
      iconUrl,
      iconSize: [15, 18],
      className: nameClass,
    });

    return (
      <Marker
        key={item.timestamp}
        position={[item.latitude, item.longitude]}
        icon={customIcon}
      >
        <Tooltip
          key={currentHoverRowList?.key}
          permanent={showTooltip}
        >
          {formatDate(item.timestamp, t('format.dayMonthYearTime'))}
          <p className="leaflet-coordinate">
            {t('dashboard.mobileDetails.mapLatitude')}
            {' '}
            {item.latitude}
          </p>
          <p className="leaflet-coordinate">
            {t('dashboard.mobileDetails.mapLongitude')}
            {' '}
            {item.longitude}
          </p>
        </Tooltip>
      </Marker>
    );
  });

  return (
    <>
      <MapContainer
        className={className}
        bounds={bounds}
        zoomControl={!isMiniMap}
        scrollWheelZoom
      >
        <TileLayer
          attribution={ATTRIBUTION}
          url={URL}
        />
        {renderMarkers()}
        <ChangeMapPosition bounds={bounds} />
      </MapContainer>
      {renderCheckBox()}
    </>

  );
};

MapLeak.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    decision: PropTypes.number,
    timestamp: PropTypes.number.isRequired,
  })),
  isMiniMap: PropTypes.bool,
  className: PropTypes.string,
  currentHoverRowList: PropTypes.shape({
    key: PropTypes.string,
    timestamp: PropTypes.number,
  }),
};

MapLeak.defaultProps = {
  data: [],
  isMiniMap: false,
  className: '',
  currentHoverRowList: null,
};
export default MapLeak;
