import { call, put, takeEvery } from 'redux-saga/effects';
import {
  COMPANIES, COMPANY, getCompaniesAction, patchCompanyAction, postCompanyAction,
} from '../actions/companyAction';
import {
  getCompaniesService,
  postCompanyService,
  patchCompanyService,
  getCompaniesByUserService,
} from '../services/companyService';
import { handleSagaErrorNotification, handleSagaSuccessNotification } from '../utils/responseHandler';

export const selectGetCompanyService = (role) => (
  role !== 1 ? getCompaniesByUserService : getCompaniesService
);

export function* getCompanies({ payload }) {
  const { showError, token, role } = payload;
  const service = selectGetCompanyService(role);
  try {
    const companies = yield call(service, token);
    const data = companies?.data;

    if (data) {
      yield put(getCompaniesAction.success(data));
    }
  } catch (err) {
    if (showError) {
      handleSagaErrorNotification(err, 'company');
    }
    yield put(getCompaniesAction.failure(err));
  }
}

export function* postCompany({ payload }) {
  const { token, role } = payload;
  const getService = selectGetCompanyService(role);
  try {
    const companyResponse = yield call(postCompanyService, payload);
    const companies = yield call(getService, token);

    const companyExist = companies.data.some(
      (company) => company.name === payload.name,
    );

    if (companyResponse && companyExist) {
      const descriptionSuccess = 'shared.notification.company.successAdd';
      yield put(getCompaniesAction.success(companies.data));
      yield put(postCompanyAction.success(descriptionSuccess));
      handleSagaSuccessNotification(descriptionSuccess);
    }

    yield put(getCompaniesAction.request({ token }));
  } catch (err) {
    handleSagaErrorNotification(err, 'company');
    yield put(postCompanyAction.failure(err));
  }
}

export function* patchCompany({ payload }) {
  const { token, role } = payload;
  const getService = selectGetCompanyService(role);
  try {
    const companyResponse = yield call(patchCompanyService, payload);

    if (companyResponse) {
      const descriptionSuccess = 'shared.notification.company.successUpdate';
      yield put(patchCompanyAction.success(descriptionSuccess));
      const newCompanies = yield call(getService, token);
      yield put(getCompaniesAction.success(newCompanies.data));
      handleSagaSuccessNotification(descriptionSuccess);
    }
  } catch (err) {
    handleSagaErrorNotification(err, 'company');
    yield put(patchCompanyAction.failure(err));
  }
}

export function* companiesSaga() {
  yield takeEvery(COMPANIES.FETCH.REQUEST, getCompanies);
  yield takeEvery(COMPANY.POST.REQUEST, postCompany);
  yield takeEvery(COMPANY.PATCH.REQUEST, patchCompany);
}
