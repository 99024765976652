import React, { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import OrdersList from './OrdersList';
import OrdersDrawer from './ordersDrawer';

const Orders = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [typeDrawer, setTypeDrawer] = useState('empty');
  const [currentStatus, setCurrentStatus] = useState({ status: 10, timestamp: 0 });
  const [currentOrder, setCurrentOrder] = useState('');
  const { t } = useTranslation();

  const handleOpenForm = () => {
    setIsOpenDrawer(true);
    setTypeDrawer('form');
  };
  const getLatestOrderStatus = (orderStatus) => orderStatus
    .sort((a, b) => b.timestamp - a.timestamp)[0].status;

  return (
    <section className="mt-large">
      <Button className="mb-large" icon={<PlusOutlined />} onClick={handleOpenForm}>{t('orders.form.titleForm')}</Button>
      <OrdersDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        typeDrawer={typeDrawer}
        setTypeDrawer={setTypeDrawer}
        currentStatus={currentStatus}
        currentOrder={currentOrder}
        setCurrentStatus={setCurrentStatus}
        getLatestOrderStatus={getLatestOrderStatus}
      />
      <OrdersList
        setIsOpenDrawer={setIsOpenDrawer}
        setTypeDrawer={setTypeDrawer}
        setCurrentStatus={setCurrentStatus}
        setCurrentOrder={setCurrentOrder}
        getLatestOrderStatus={getLatestOrderStatus}
      />
    </section>
  );
};

export default Orders;
