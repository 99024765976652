import { put, takeEvery } from 'redux-saga/effects';
import { LOGOUT, logoutAction } from '../actions/loginAction';

export function* logout() {
  try {
    yield put(logoutAction.success());
  } catch (error) {
    yield put(logoutAction.failure(error));
  }
}

export default function* logoutSaga() {
  yield takeEvery(LOGOUT.POST.REQUEST, logout);
}
