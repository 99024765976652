import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TagOutlined } from '@ant-design/icons';
import { renderTags } from '../../utils/utils';
import EntityListTemplate from '../shared/list/EntityListTemplate';

const CompaniesList = () => {
  const { companies } = useSelector((state) => state.companies);
  const { agencies } = useSelector((state) => state.agencies);
  const { t } = useTranslation();

  const companyNameColumn = {
    title: t('dashboard.company.associatedAgencies'),
    dataIndex: 'associatedAgencies',
    key: 'associatedAgencies',
    render: (text) => {
      const associatedAgencies = agencies?.filter((agency) => agency.companyId === text);
      return associatedAgencies?.map((agency) => {
        const { id, name } = agency;
        return renderTags(
          id,
          <TagOutlined className="button-reset" />,
          name,
        );
      });
    },
    responsive: ['md', 'lg'],
    width: 90,
  };

  return (
    <EntityListTemplate
      type="company"
      entity={companies}
      addColumn={companyNameColumn}
    />
  );
};
export default CompaniesList;
