import { call, put, takeEvery } from 'redux-saga/effects';
import {
  ORDER,
  ORDERDETAILS,
  ORDERSTATUS,
  getOrdersAction,
  postOrderAction,
  patchOrderDetailsAction,
  patchOrderStatusAction,
  getLastOrderAction,
} from '../actions/orderAction';
import {
  getOrdersService,
  postOrderService,
  patchOrderDetailsService,
  patchOrderStatusService,
  getOrdersByUserService,
} from '../services/orderService';
import { handleSagaErrorNotification, handleSagaSuccessNotification } from '../utils/responseHandler';
import { isProdEnv } from '../../utils/utils';

export const selectGetOrdersService = (role) => (
  role !== 1 ? getOrdersByUserService : getOrdersService
);

export function* getOrders({ payload }) {
  const { token, role } = payload;
  const service = selectGetOrdersService(role);
  try {
    const orders = yield call(service, token);
    const { data } = orders;

    if (data) {
      yield put(getOrdersAction.success(data));
    } else {
      yield put(getOrdersAction.failure('shared.notification.order.noData'));
    }
  } catch (error) {
    if (!isProdEnv) {
      handleSagaErrorNotification(error, 'order');
    }
    yield put(getOrdersAction.failure(error));
  }
}

export function* postOrder({ payload }) {
  const { token, role } = payload;
  const getService = selectGetOrdersService(role);
  try {
    const postOrderResponse = yield call(postOrderService, payload);

    if (postOrderResponse) {
      const descriptionSuccess = 'shared.notification.order.successAdd';
      yield put(getLastOrderAction.success(postOrderResponse));
      const orders = yield call(getService, token);
      yield put(getOrdersAction.success(orders.data));
      yield put(postOrderAction.success(descriptionSuccess));
      yield put(postOrderAction.failure(null));
      handleSagaSuccessNotification(descriptionSuccess);
    }
  } catch (error) {
    handleSagaErrorNotification(error, 'order');
    yield put(postOrderAction.failure(error));
  }
}

export function* patchOrderDetails({ payload }) {
  const { token } = payload;
  try {
    const orderResponse = yield call(patchOrderDetailsService, payload);
    if (orderResponse) {
      const descriptionSuccess = 'shared.notification.order.successUpdateDetails';
      yield put(patchOrderDetailsAction.success(descriptionSuccess));
      handleSagaSuccessNotification(descriptionSuccess);
    }
    const newOrderStatus = yield call(getOrdersService, token);
    if (newOrderStatus) {
      yield put(getOrdersAction.success(newOrderStatus.data));
    }
  } catch (error) {
    handleSagaErrorNotification(error, 'order');
    yield put(patchOrderDetailsAction.failure(error));
  }
}

export function* patchOrderStatus({ payload }) {
  const { token } = payload;
  try {
    yield call(patchOrderStatusService, payload);
    const newOrderStatus = yield call(getOrdersService, token);
    if (newOrderStatus) {
      yield put(getOrdersAction.success(newOrderStatus.data));
    }
    yield put(patchOrderStatusAction.success('shared.notification.order.successUpdateDetails'));
  } catch (error) {
    handleSagaErrorNotification(error, 'order');
    yield put(patchOrderStatusAction.failure(error));
  }
}

export function* ordersSaga() {
  yield takeEvery(ORDER.FETCH.REQUEST, getOrders);
  yield takeEvery(ORDER.POST.REQUEST, postOrder);
  yield takeEvery(ORDERDETAILS.PATCH.REQUEST, patchOrderDetails);
  yield takeEvery(ORDERSTATUS.PATCH.REQUEST, patchOrderStatus);
}
