export const getTimestampByStatus = (orders, currentOrder) => {
  const renderStatusCurrentOrder = orders?.filter((order) => order.id === currentOrder);
  const allStatus = renderStatusCurrentOrder[0]?.status;
  return allStatus;
};

export const setColorByStatus = (currentIdStatus, lastCurrentStatus) => {
  switch (lastCurrentStatus) {
    case 10:
    case 11:
    case 20:
    case 21:
    case 22:
    case 30:
      if (currentIdStatus <= lastCurrentStatus) {
        return 'green';
      }
      return 'lightgrey';
    case 32:
      return 'grey';
    case 33:
    case 100:
      return 'red';
    case 31:
      return 'orange';
    default:
      return 'blue';
  }
};
